export const ApiURL = "https://www.simplycollaborate.com/api";
export const languageText = "";
export const minPasswordLength = 8;
export const minPasswordStrength = 3;
export const auth = true;

export const locationId = "8AHACSTCAFPVV";
export const appId = "sandbox-sq0idb-xlxF1YKo4ZVvpbNW8X7_lQ";

export const subscriptionTypes = [
  {
    id: 1,
    devSsubscriptionId: "XGHHPAW5CZXVRTGLN7SYMOIR",
    subscriptionId: "F22PMMRBCNEHLHIISN2AOFMD",
    title: "Basic",
    subscriptionType: "basic",
    startingPrice: 49,
    features: [
      "Double Encrypted Files",
      "Can Upload files",
      "Create Folders",
      "Invite Users",
    ],
    numberOfClients: "C862B1FA-DDD3-4A7C-9968-B400DB392448",
    amountOfDiskSpace: 1,
  },
  {
    id: 2,
    devSsubscriptionId: "FIRWPBHYOZHHDPPIZHCGUVRA",
    subscriptionId: "6UCWVP6P6SEDHVIZM32BQ6HY",
    title: "Premium",
    subscriptionType: "premium",
    startingPrice: 99,
    features: [
      "Double Encrypted Files",
      "Can Upload files",
      "Create Folders",
      "Invite Users",
      "Document Requests",
    ],
    numberOfClients: "C862B1FA-DDD3-4A7C-9968-B400DB392448",
    amountOfDiskSpace: 1,
  },
  {
    id: 3,
    devSsubscriptionId: "RMQY33P4GYZKNZLWJSWUDD3W",
    subscriptionId: "JMCHM7GGOBCRMBI2GYZOAC2P",
    title: "Pro",
    subscriptionType: "pro",
    startingPrice: 149,
    features: [
      "Double Encrypted Files",
      "Can Upload files",
      "Create Folders",
      "Invite Users",
      "Document Requests",
      "Document Signatures",
      "Signature Requests",
    ],
    numberOfClients: "C862B1FA-DDD3-4A7C-9968-B400DB392448",
    amountOfDiskSpace: 1,
  },
];

export const reservedSites = ["www", "test", "demo"];
