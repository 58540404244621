import React from "react";
import LayoutNav from "./LayoutNav";
import { Outlet } from "react-router-dom";
import LayoutFooter from "./LayoutFooter";
import ScrollToTop from "./ScrollToTop";

const Layout = ({ children }) => {
  return (
    <ScrollToTop>
      <main className="main" id="top">
        <LayoutNav />
        {!children && <Outlet />}
        {children}
        <LayoutFooter />
      </main>
    </ScrollToTop>
  );
};
export default Layout;
