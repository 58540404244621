import PropTypes from "prop-types";
import React from "react";

import FormInputText from "./FormInputText";
import FormInputDisplay from "./FormInputDisplay";
import FormInputTextArea from "./FormInputTextArea";
import FormInputRadio from "./FormInputRadio";
import FormInputFile from "./FormInputFile";
import FormInputImage from "./FormInputImage";
import FormInputSelect from "./FormInputSelect";
import FormInputCheckbox from "./FormInputCheckbox";
import FormInputEditor from "./FormInputEditor";
import FormInputToggle from "./FormInputToggle";
import FormInputPassword from "./FormInputPassword";
import FormInputHidden from "./FormInputHidden";
import FormInputFancyCheckbox from "./FormInputFancyCheckbox";
import FormLinkedTable from "./FormLinkedTable";
import FormInputDate from "./FormInputDate";
import FormInputSimpleEditor from "./FormInputSimpleEditor";
import FormInputState from "./FormInputState";
import FormInputCountry from "./FormInputCountry";
import FormInputDataList from "./FormInputDataList";

const FormInput = (props) => {
  let { label, type, inputSize, floatingLabel } = props;

  let inputSizeClass = "form-control";
  if (inputSize === "sm") inputSizeClass += " form-control-sm";
  if (inputSize === "lg") inputSizeClass += " form-control-lg";

  let floatingLabelClass = "form-group";
  if (floatingLabel) floatingLabelClass = " form-floating";

  let divColSize = 12;
  if (props.colSize === 0) divColSize = 0;
  if (props.colSize === 1) divColSize = 12;
  if (props.colSize === 2) divColSize = 6;
  if (props.colSize === 3) divColSize = 4;
  if (props.colSize === 4) divColSize = 8;

  props = { ...props, inputSizeClass, floatingLabelClass, divColSize };

  switch (type) {
    case "checkbox":
      return <FormInputCheckbox {...props} />;

    case "date":
      return <FormInputDate {...props} />;

    case "display":
      return <FormInputDisplay {...props} />;

    case "editor":
    case "content":
      return <FormInputEditor {...props} />;

    case "email":
      return <FormInputText {...props} />;

    case "fancyCheckbox":
      return <FormInputFancyCheckbox {...props} />;

    case "file":
      return <FormInputFile {...props} />;

    case "hidden":
      return <FormInputHidden {...props} />;

    case "upload":
    case "image":
      return <FormInputImage {...props} />;

    case "number":
      return <FormInputText {...props} />;

    case "password":
      return <FormInputPassword {...props} />;

    case "radio":
      return <FormInputRadio {...props} />;

    case "select":
    case "dropdown":
      return <FormInputSelect {...props} />;

    case "datalist":
      return <FormInputDataList {...props} />;

    case "simpleEditor":
      return <FormInputSimpleEditor {...props} />;

    case "state":
      return <FormInputState {...props} />;

    case "country":
      return <FormInputCountry {...props} />;

    case "text":
      return <FormInputText {...props} />;

    case "textarea":
      return <FormInputTextArea {...props} />;

    case "toggle":
      return <FormInputToggle {...props} />;

    case "title":
      return (
        <div className={`col-12 p-2 bg-secondary text-white mb-2`}>
          <b>{label}</b>
        </div>
      );

    case "linkedTable":
      return <FormLinkedTable {...props} />;

    default:
      return <FormInputText {...props} />;
  }
};
FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  inputSize: PropTypes.string,
  floatingLabel: PropTypes.bool,
  colSize: PropTypes.number,
  hideBorder: PropTypes.bool,
  hideLabel: PropTypes.bool,
  onBlur: PropTypes.func,
  hideMeter: PropTypes.bool,
  refProp: PropTypes.object,
  tinyMCEApiKey: PropTypes.string,
  uploadUrl: PropTypes.string,
};
export default FormInput;
