import React, { Fragment } from "react";
const Customers = () => {
  const testimonials = [
    {
      image: "",
      from: "Sarah M.",
      message:
        "I've been using this company for over a year now and their online document security solutions have been a game-changer for my business. I feel confident knowing that my confidential information is safe and secure, and their customer service is exceptional.",
    },
    {
      from: "John D.",
      message:
        "I was skeptical at first, but after using their services for a few months, I'm blown away by how easy and effective it is to secure my documents online. I highly recommend this company to anyone who wants to protect their sensitive information.",
    },
    {
      from: "Rachel T.",
      message:
        "As someone who works in the legal field, document security is crucial. This company has exceeded my expectations and has provided me with peace of mind knowing that my client's documents are always protected. Their software is user-friendly and their team is always available to answer any questions I have. I highly recommend this company!",
    },
    {
      from: "Michael S.",
      message:
        "I was looking for a secure way to store and share important documents with my team, and this company delivered. Their online document security solutions have streamlined our workflow and provided us with a reliable and efficient way to collaborate on projects.",
    },
    {
      from: "Emily W.",
      message:
        "I was hesitant to trust an online company with my confidential information, but this company has proven to be trustworthy and reliable. Their security measures are top-notch and their customer support team is always available to address any concerns I may have.",
    },
    {
      from: "David H.",
      message:
        "I've been using this company for a few months now and I'm impressed by their commitment to keeping my documents safe. Their software is user-friendly and easy to navigate, and I appreciate the regular updates on their security measures.",
    },
    {
      from: "Maria P.",
      message:
        "I've tried other document security solutions in the past, but none have compared to the level of protection and customer service that this company offers. I highly recommend them to anyone looking for a secure and user-friendly solution.",
    },
    {
      from: "Alex K.",
      message:
        "As a small business owner, I'm always looking for ways to improve my company's security. This company has been instrumental in providing me with a secure platform to store and share sensitive documents. I'm grateful for their services and would recommend them to anyone in need of online document security.",
    },
  ];

  return (
    <section className="py-0 overflow-hidden" id="banner" data-bs-theme="light">
      <div
        className="bg-holder overlay"
        style={{
          backgroundImage: "url(/assets/img/generic/1.jpg)",
          backgroundPosition: "center bottom",
        }}
      />
      <div className="container">
        <div className="row flex-center pt-1 pt-lg-8 pb-lg-9 pb-xl-0">
          <h1 className="text-white text-center">Testimonials</h1>
          <div className="col-12">
            <div className="card mb-3">
              <div className="card-body p-5">
                {testimonials.map((testimonial, index) => {
                  return (
                    <Fragment key={`testimonial${index}`}>
                      <p className="fs--2 mb-0 fs-1">
                        {testimonial.message}
                        <br />- {testimonial.from}
                      </p>
                      <hr className="my-5" />
                    </Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Customers;
