import React, { useReducer, useState } from "react";
import { Helmet } from "react-helmet";
import { FormInput } from "cmation-formsbuilder";
import { ApiURL } from "../../../config";

function SignUpWorkspace({ processSubscription, data, backStep }) {
  const [error, setError] = useState([]);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [referralCode, setReferralCode] = useState(data.referralCode || "");
  const [discountCode, setDiscountCode] = useState(data.discountCode || "");
  const [referralCodeSuccess, setReferralCodeSuccess] = useState(false);
  const [discountCodeSuccess, setDiscountCodeSuccess] = useState(false);

  const continueSignup = () => {
    processSubscription(5, { referralCode, discountCode });
  };

  const checkReferralCode = () => {
    let errorTMP = error;
    delete errorTMP["referralCode"];
    let hasError = false;
    if (!referralCode) {
      errorTMP["referralCode"] = "Field is blank!";
      hasError = true;
    }
    if (!hasError) {
      fetch(`${ApiURL}/subscription/checkReferralCode`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ referralCode }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (!res.status) {
            let errorTMP = error;
            errorTMP["referralCode"] = res.message;
            setError(errorTMP);
            setReferralCodeSuccess(false);
          }
          if (res.status) {
            setReferralCodeSuccess(true);
          }
          forceUpdate();
        })
        .catch(() => {});
    }
  };

  const checkDiscountCode = () => {
    let errorTMP = error;
    delete errorTMP["discountCode"];
    let hasError = false;
    if (!discountCode) {
      errorTMP["discountCode"] = "Field is blank!";
      hasError = true;
    }
    if (!hasError) {
      fetch(`${ApiURL}/subscription/checkDiscountCode`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ discountCode }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (!res.status) {
            let errorTMP = error;
            errorTMP["discountCode"] = res.message;
            setError(errorTMP);
            setDiscountCodeSuccess(false);
          }
          if (res.status) {
            setDiscountCodeSuccess(true);
          }
          forceUpdate();
        })
        .catch(() => {});
    }
  };

  return (
    <>
      <Helmet>
        <title>Simply Collaborate | Enter Referral Code</title>
        <meta name="description" content="" />
        <meta name="robots" content="" />
        <meta name="googlebot" content="" />
        <meta name="keywords" content="" />
        <meta name="theme-color" content="#008f68" />
      </Helmet>
      <div className="container">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="row mt-4 justify-content-center">
            <div className="col-6">
              <h2>Referral Code</h2>
              <p>If you have a referral code enter it below or click Skip</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <FormInput
              type="text"
              id="referralCode"
              name="referralCode"
              hideBorder={true}
              colSize={2}
              value={referralCode}
              placeholder="Referral Code"
              onChange={(value) => setReferralCode(value)}
              error={error["referralCode"]}
            >
              <button className="btn btn-secondary" onClick={checkReferralCode}>
                Check
              </button>
            </FormInput>
          </div>
          {referralCodeSuccess && (
            <div className="row mb-4 mx-2 justify-content-center">
              <div className="col-6 p-1 alert alert-success">
                <button
                  className="btn btn-sm btn-outline-success"
                  onClick={() => {
                    setReferralCodeSuccess(false);
                    setReferralCode("");
                  }}
                >
                  <i className="fas fa-times" />
                </button>{" "}
                &nbsp; Referral Code Applied : {referralCode}
              </div>
            </div>
          )}
          <div className="row mt-4 justify-content-center">
            <div className="col-6">
              <h2>Discount Code</h2>
              <p>If you have a discount code enter it below or click Skip</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <FormInput
              type="text"
              id="discountCode"
              name="discountCode"
              hideBorder={true}
              colSize={2}
              value={discountCode}
              placeholder="Discount Code"
              onChange={(value) => setDiscountCode(value)}
              error={error["discountCode"]}
            >
              <button className="btn btn-secondary" onClick={checkDiscountCode}>
                Check
              </button>
            </FormInput>
          </div>
          {discountCodeSuccess && (
            <div className="row mb-4 mx-2 p-0 justify-content-center">
              <div className="col-6 p-1 alert alert-success">
                <button
                  className="btn btn-sm btn-outline-success"
                  onClick={() => {
                    setDiscountCodeSuccess(false);
                    setDiscountCode("");
                  }}
                >
                  <i className="fas fa-times" />
                </button>{" "}
                &nbsp; Discount Code Applied : {discountCode}
              </div>
            </div>
          )}

          <div className="row py-3 justify-content-center">
            <div className="col-3">
              <button className="btn btn-sm btn-secondary" onClick={backStep}>
                Cancel Signup
              </button>
            </div>
            <div className="col-3 text-end">
              {(referralCodeSuccess || discountCodeSuccess) && (
                <button
                  type={"button"}
                  className="btn btn-sm btn-primary"
                  onClick={continueSignup}
                >
                  Continue
                </button>
              )}
              {!referralCodeSuccess && !discountCodeSuccess && (
                <button
                  type={"submit"}
                  className="btn btn-sm btn-primary"
                  onClick={continueSignup}
                >
                  Skip
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default SignUpWorkspace;
