import React, { useState } from "react";
import classnames from "classnames";
import InputError from "./InputError";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import InputHelpText from "./InputHelpText";
const moment = require("moment"); // require

const FormInputDate = (props) => {
  const value = props.value ? new Date(props.value + "T00:00:00") : new Date();
  const [newDate, setNewDate] = useState(value);

  const onChangeDate = (date) => {
    if (date) {
      let changeDate = moment(date).format("YYYY-MM-DD");
      setNewDate(changeDate);
      props.onChange(changeDate);
    } else {
      props.onChange("");
      setNewDate(new Date());
    }
  };

  return (
    <div className={`col-12 align-bottom col-sm-${props.divColSize}`}>
      <div className="container p-0">
        <div className="form-group row border-bottom pb-2">
          <label
            htmlFor={props.name}
            className={classnames("col-sm-12 m-0 pt-2 pb-2", {
              "is-invalid": props.error,
            })}
          >
            {props.label}
          </label>
          <div className="col-sm-12">
            <Calendar
              value={newDate}
              onChange={onChangeDate}
              className={classnames(props.inputSizeClass, {
                "is-invalid": props.error,
              })}
            />
          </div>

          <InputError error={props.error} />
          <InputHelpText helpText={props.helpText} />
        </div>
      </div>
    </div>
  );
};

export default FormInputDate;
