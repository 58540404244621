import React from "react";
import { Helmet } from "react-helmet";

function SignUpCompleting({ processSubscription }) {
  return (
    <>
      <Helmet>
        <title>Simply Collaborate | Sign-up Complete</title>
        <meta name="description" content="" />
        <meta name="robots" content="" />
        <meta name="googlebot" content="" />
        <meta name="keywords" content="" />
        <meta name="theme-color" content="#008f68" />
      </Helmet>
      <div className="container subscriptionHeader">
        <div className="row p-5">
          <div className="col-12 p-5 text-white">
            <h1 className="text-center py-3">Creating your account</h1>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <p className="text-center py-5">
              If you account is created successfully you will be redirected to
              the login page.
            </p>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-6 text-center">
            <button
              className="btn btn-sm btn-primary"
              onClick={() => processSubscription(7, [])}
            >
              Re-Try
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUpCompleting;
