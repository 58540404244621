import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FormInput from "../../components/cmation-formsbuilder";
import { ApiURL } from "../../config";
import { TurnstileWidget } from "../../components/TurnstileWidget";

const Login = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState([]);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const checkAuth = useCallback(() => {
    fetch(`${ApiURL}/auth/user`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === true) {
          navigate("/account/");
        }
      })
      .catch((err) => {
        console.log("authLogin Error", err);
      });
  }, [navigate]);

  const onFormSubmit = (e) => {
    e.preventDefault();
  };

  const onSubmit = (e) => {
    setErrorMessage("");
    e.preventDefault();
    let newError = [];
    if (!username) {
      newError["username"] = "You did not enter your Email";
      setError(newError);
    }
    if (!password) {
      newError["password"] = "You did not enter your Password";
      setError(newError);
    }
    if (username && password) {
      setError([]);
      // embed login here
      fetch(`${ApiURL}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ username, password }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === false) {
            let newError = [];
            setErrorMessage(res.details);
            setError(newError);
          } else {
            localStorage.setItem("token", res.token);
            navigate("/account/");
          }
        })
        .catch((err) => {});
    } else {
      setErrorMessage("Please fill in all fields");
    }
  };

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  return (
    <section className="py-0 overflow-hidden" id="banner" data-bs-theme="dark">
      <div className="container-fluid">
        <div className="row min-vh-100 bg-100">
          <div className="col-6 d-none d-lg-block position-relative">
            <div
              className="bg-holder"
              style={{
                backgroundImage: "url(../../../assets/img/generic/14.jpg)",
                backgroundPosition: "50% 20%",
              }}
            ></div>
          </div>
          <div className="col-sm-10 col-md-6 px-sm-0 align-self-center mx-auto py-5">
            <div className="row justify-content-center g-0">
              <div className="col-lg-9 col-xl-8 col-xxl-6">
                <div className="card">
                  <div className="card-header bg-circle-shape bg-shape text-center p-2">
                    <h4
                      className="font-sans-serif fw-bolder fs-4 z-1 position-relative link-light"
                      data-bs-theme="light"
                    >
                      SimplyCollaborate
                    </h4>
                  </div>
                  <div className="card-body p-4">
                    <div className="row flex-between-center">
                      <div className="col-auto">
                        <h3>Login</h3>
                      </div>
                      <div className="col-auto fs--1 text-600">
                        <span className="mb-0 fw-semi-bold">New User?</span>{" "}
                        <span>
                          <Link to="/product/pricing">Create account</Link>
                        </span>
                      </div>
                    </div>
                    {errorMessage && (
                      <div className="row">
                        <div className="col-12">
                          <div
                            className="alert alert-danger alert-dismissible fade show"
                            role="alert"
                          >
                            <div className="alert-message">{errorMessage}</div>
                          </div>
                        </div>
                      </div>
                    )}
                    <form onSubmit={onFormSubmit}>
                      <FormInput
                        colSize={1}
                        type="email"
                        id="username"
                        label="Email"
                        name="username"
                        value={username}
                        floatingLabel={true}
                        placeholder="Enter your email"
                        autoComplete="username"
                        onChange={(value) => setUsername(value)}
                        error={error["username"]}
                      />
                      <FormInput
                        colSize={1}
                        type="password"
                        id="password"
                        label="Password"
                        name="password"
                        value={password}
                        hideMeter={true}
                        floatingLabel={true}
                        placeholder="Enter your password"
                        autoComplete="current-password"
                        onChange={(value) => setPassword(value)}
                        error={error["password"]}
                      >
                        <Link to="/auth/lost-password">Forgot password?</Link>
                      </FormInput>
                      <div className="container">
                        {/*<div className="row">*/}
                        {/*  <div className="col-12">*/}
                        {/*    <TurnstileWidget />*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                        <div className="row">
                          <div className="col-6">
                            <Link className="btn btn-secondary mt-3" to="/">
                              <span
                                className="fas fa-chevron-left me-1"
                                data-fa-transform="shrink-4 down-1"
                              />
                              Home
                            </Link>
                          </div>
                          <div className="col-6 text-end">
                            <button
                              className="btn btn-primary mt-3"
                              onClick={onSubmit}
                            >
                              Login
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Login;
