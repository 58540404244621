import React from "react";
import { Link } from "react-router-dom";
const Blank = () => {
  const videos = [
    {
      image: "/assets/img/elearning/courses/course1.png",
      title: "Learn how to create customers",
      link: "/academy/how-to-create-an-organization",
      video: "",
      id: 1,
    },
    {
      image: "/assets/img/elearning/courses/course1.png",
      title: "Learn how to invite users",
      link: "/academy/how-to-invite-a-user",
      video: "",
      id: 2,
    },
    {
      image: "/assets/img/elearning/courses/course1.png",
      title: "Learn how to upload a file",
      link: "/academy/how-to-upload-a-file",
      video: "",
      id: 3,
    },
    {
      image: "/assets/img/elearning/courses/course1.png",
      title: "Customizing your email templates",
      link: "/academy/customizing-your-email-templates",
      video: "",
      id: 4,
    },
    {
      image: "/assets/img/elearning/courses/course1.png",
      title: "Managing Files & Disk space",
      link: "/academy/customizing-your-email-templates",
      video: "",
      id: 5,
    },
    {
      image: "/assets/img/elearning/courses/course1.png",
      title: "Creating a Signature Document",
      link: "/academy/customizing-your-email-templates",
      video: "",
      id: 6,
    },
    {
      image: "/assets/img/elearning/courses/course1.png",
      title: "Chatting with your customers",
      link: "/academy/customizing-your-email-templates",
      video: "",
      id: 7,
    },
    {
      image: "/assets/img/elearning/courses/course1.png",
      title: "Notifications",
      link: "/academy/customizing-your-email-templates",
      video: "",
      id: 7,
    },
    {
      image: "/assets/img/elearning/courses/course1.png",
      title: "Favorite a file",
      link: "/academy/customizing-your-email-templates",
      video: "",
      id: 7,
    },
  ];

  return (
    <section className="py-0 overflow-hidden" id="banner" data-bs-theme="light">
      <div
        className="bg-holder overlay"
        style={{
          backgroundImage: "url(/assets/img/generic/bg-1.jpg)",
          backgroundPosition: "center bottom",
        }}
      />
      <div className="container">
        <div className="row flex-center pt-1 pt-lg-8 pb-lg-9 pb-xl-0">
          <div className="col-12">
            <h1 className="text-white">Simply Collaborate Academy</h1>
            <p className="text-white">
              Learn how to use our software, click on a video below.
            </p>
          </div>
          <div className="col-12">
            <div className="row mb-3 g-3">
              {videos.map((video, index) => {
                return (
                  <article className="col-md-4 col-xxl-4">
                    <div className="card h-100 overflow-hidden">
                      <div className="card-body p-0 d-flex flex-column justify-content-between">
                        <div>
                          <div className="hoverbox text-center">
                            <Link
                              className="text-decoration-none"
                              to={`/academy/${video.id}`}
                              data-gallery="attachment-bg"
                            >
                              <img
                                className="w-100 h-100 object-fit-cover"
                                src={video.image}
                                alt=""
                              />
                            </Link>
                            <div className="hoverbox-content flex-center pe-none bg-holder overlay overlay-2">
                              <img
                                className="z-1"
                                src="/assets/img/icons/play.svg"
                                width="60"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="p-3">
                            <h5 className="fs-0 mb-2">
                              <Link
                                className="text-dark"
                                data-gallery="attachment-bg"
                              >
                                {video.title}
                              </Link>
                            </h5>
                            <h5 className="fs-0">
                              <Link data-gallery="attachment-bg">
                                Watch Video
                              </Link>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Blank;
