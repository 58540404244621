import React from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import ScrollToTop from "./components/layouts/ScrollToTop";
import Layout from "./components/layouts/Layout";
import Landing from "./pages/Landing";
import Error404 from "./pages/404";
import Pricing from "./pages/Pricing";
import FAQs from "./pages/FAQs";
import Customers from "./pages/Customers";
import Terms from "./pages/docs/Terms";
import Privacy from "./pages/docs/Privacy";
import About from "./pages/About";
import Features from "./pages/Features";
import Contact from "./pages/Contact";
import Academy from "./pages/Academy";
import Changelog from "./pages/Changelog";
import ReferralProgram from "./pages/ReferralProgram";
import SignUp from "./pages/product/signup";
import Login from "./pages/auth/Login";
import Logout from "./pages/auth/Logout";
import AuthLayout from "./components/layouts/AuthLayout";
import LostPassword from "./pages/auth/LostPassword";
import Dashboard from "./pages/account/Dashboard";
import Billing from "./pages/account/Billing";
import Subscription from "./pages/account/Subscription";
import ProfileNav from "./components/layouts/ProfileNav";
import Profile from "./pages/account/Profile";
import Support from "./pages/account/Support";
import Password from "./pages/account/Password";
import Workspace from "./pages/account/Workspace";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route
        index
        element={
          <Layout>
            <Landing />
          </Layout>
        }
      />
      <Route
        path="academy"
        element={
          <Layout>
            <Academy />
          </Layout>
        }
      />

      <Route path="page" element={<Layout />}>
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path="customers" element={<Customers />} />
        <Route path="faqs" element={<FAQs />} />
      </Route>

      <Route path="docs" element={<Layout />}>
        <Route path="terms" element={<Terms />} />
        <Route path="privacy" element={<Privacy />} />
      </Route>

      <Route path="product" element={<Layout />}>
        <Route path="changelog" element={<Changelog />} />
        <Route path="features" element={<Features />} />
        <Route path="pricing" element={<Pricing />} />
        <Route path="referral" element={<ReferralProgram />} />
        <Route path="signup">
          <Route
            path=":subscriptionType/:numberOfClients/:amountOfDiskSpace"
            element={<SignUp />}
          />
        </Route>
      </Route>

      <Route
        path="account"
        element={
          <Layout>
            <ProfileNav />
          </Layout>
        }
      >
        <Route index element={<Dashboard />} />
        <Route path="billing" element={<Billing />} />
        <Route path="password" element={<Password />} />
        <Route path="profile" element={<Profile />} />
        <Route path="subscription" element={<Subscription />} />
        <Route path="support" element={<Support />} />
        <Route path="workspace" element={<Workspace />} />
      </Route>

      <Route path="auth" element={<AuthLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="logout" element={<Logout />} />
        <Route path="lost-password" element={<LostPassword />} />
      </Route>

      <Route path="*" element={<Error404 />} />
    </Route>
  ),
  {
    basename: "/",
  }
);

function App() {
  return <RouterProvider router={router}></RouterProvider>;
}

export default App;
