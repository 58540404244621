import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import InputError from "./InputError";
import InputHelpText from "./InputHelpText";

const FormInputEditor = (props) => {
  return (
    <div className={`col-12 align-bottom col-sm-${props.divColSize}`}>
      <div className="container p-1">
        <div className="form-group row border-bottom pb-2">
          <label htmlFor={props.name} className="col-sm-12">
            {props.label}
          </label>
          <Editor
            className="col-sm-12"
            apiKey={props.tinyMCEApiKey}
            value={props.value}
            init={{
              images_upload_url: props.uploadUrl,
              file_picker_types: "file image media",
              block_unsupported_drop: true,
              images_upload_credentials: true,
              height: 450,
              width: "100%",
              schema: "html5",
              plugins:
                "link image code lists advlist autolink media table paste help wordcount fullscreen imagetools",
              toolbar:
                "insertfile link image | undo redo | formatselect | bold italic | alignleft aligncenter alignright | code removeformat fullscreen",
            }}
            onEditorChange={(e) => props.onChange(e)}
          />

          <InputError error={props.error} />
          <InputHelpText helpText={props.helpText} />
          <hr />
        </div>
      </div>
    </div>
  );
};

export default FormInputEditor;
