import { Helmet } from "react-helmet";
import React from "react";

const Loading = () => {
  return (
    <>
      <div className="container pb-5">
        <div className="row flex-center">
          <div className="col-lg-4">
            <div className="card mb-3">
              <div className="card-body text-center">
                <div className="spinner-border text-warning" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Loading;
