import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const DashboardStats = () => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "% of subscription values used",
      },
    },
  };
  const stats = [
    {
      date: "3/1",
      clients: 10,
      diskSpace: 1.03,
    },
    {
      date: "3/2",
      clients: 10,
      diskSpace: 1.07,
    },
    {
      date: "3/3",
      clients: 11,
      diskSpace: 1.7,
    },
    {
      date: "3/4",
      clients: 11,
      diskSpace: 1.8,
    },
    {
      date: "3/5",
      clients: 11,
      diskSpace: 2.2,
    },
    {
      date: "3/6",
      clients: 12,
      diskSpace: 2.5,
    },
    {
      date: "3/7",
      clients: 12,
      diskSpace: 2.5,
    },
    {
      date: "3/8",
      clients: 12,
      diskSpace: 2.5,
    },
    {
      date: "3/9",
      clients: 12,
      diskSpace: 2.5,
    },
    {
      date: "3/10",
      clients: 12,
      diskSpace: 2.5,
    },
    {
      date: "3/11",
      clients: 12,
      diskSpace: 2.5,
    },
    {
      date: "3/12",
      clients: 12,
      diskSpace: 2.5,
    },
    {
      date: "3/13",
      clients: 12,
      diskSpace: 2.5,
    },
    {
      date: "3/14",
      clients: 12,
      diskSpace: 2.5,
    },
    {
      date: "3/15",
      clients: 12,
      diskSpace: 2.5,
    },
    {
      date: "3/16",
      clients: 12,
      diskSpace: 2.5,
    },
    {
      date: "3/17",
      clients: 12,
      diskSpace: 2.5,
    },
    {
      date: "3/18",
      clients: 12,
      diskSpace: 2.5,
    },
    {
      date: "3/19",
      clients: 12,
      diskSpace: 2.5,
    },
    {
      date: "3/20",
      clients: 12,
      diskSpace: 2.5,
    },
    {
      date: "3/21",
      clients: 12,
      diskSpace: 2.5,
    },
    {
      date: "3/22",
      clients: 12,
      diskSpace: 2.5,
    },
    {
      date: "3/23",
      clients: 14,
      diskSpace: 2.5,
    },
    {
      date: "3/24",
      clients: 14,
      diskSpace: 2.5,
    },
    {
      date: "3/25",
      clients: 12,
      diskSpace: 2.5,
    },
    {
      date: "3/26",
      clients: 12,
      diskSpace: 2.5,
    },
    {
      date: "3/27",
      clients: 13,
      diskSpace: 2.6,
    },
    {
      date: "3/28",
      clients: 13,
      diskSpace: 2.7,
    },
    {
      date: "3/29",
      clients: 13,
      diskSpace: 2.8,
    },
    {
      date: "3/30",
      clients: 13,
      diskSpace: 3.2,
    },
    {
      date: "3/31",
      clients: 14,
      diskSpace: 4.2,
    },
  ];
  const dataLimits = {
    clients: 25,
    diskSpace: 5,
  };

  const getLabels = () => {
    return stats.map(function (i) {
      return i.date;
    });
  };
  const getDiskspaceData = () => {
    return stats.map(function (i) {
      return (i.diskSpace / dataLimits.diskSpace) * 100;
    });
  };
  const getClientsData = () => {
    return stats.map(function (i) {
      return (i.clients / dataLimits.clients) * 100;
    });
  };

  let labels = getLabels();
  const data = {
    labels,
    datasets: [
      {
        label: "% Diskspace Used",
        data: getDiskspaceData(),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "% Clients Used",
        data: getClientsData(),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return (
    <>
      <Line options={options} data={data} />
    </>
  );
};

export default DashboardStats;
