import React from "react";
const Terms = () => {
  return (
    <section className="py-0 overflow-hidden" id="banner" data-bs-theme="light">
      <div
        className="bg-holder overlay"
        style={{
          backgroundImage: "url(/assets/img/generic/14.jpg)",
          backgroundPosition: "center bottom",
        }}
      />
      <div className="container">
        <div className="row flex-center pt-1 pt-lg-8 pb-lg-9 pb-xl-0">
          <div className="col-12">
            <h1 className="text-white text-center">Terms of Service</h1>
          </div>
          <div className="col-12">
            <div className="card mb-3">
              <div className="card-body p-5">
                <p>
                  Thank you for choosing our company for your online document
                  security needs. By using our services, you agree to comply
                  with the following terms and conditions:
                </p>

                <p>
                  <b>Service Description</b>
                  <br />
                  Our company provides online document security solutions for
                  individuals and businesses. We strive to keep your
                  confidential information safe and secure through our
                  state-of-the-art technology and industry best practices.
                </p>

                <p>
                  <b>Account Registration</b>
                  <br />
                  To use our services, you must register an account with us. You
                  are responsible for maintaining the confidentiality of your
                  account information and are solely responsible for all
                  activities that occur under your account. You must notify us
                  immediately if you suspect any unauthorized access to your
                  account.
                </p>

                <p>
                  <b>User Content</b>
                  <br />
                  You are solely responsible for the content you upload, store,
                  and share through our services. By using our services, you
                  grant us a non-exclusive, transferable, sub-licensable,
                  royalty-free, worldwide license to use any content you upload
                  for the purpose of providing our services to you.
                </p>

                <p>
                  <b>Prohibited Activities</b>
                  <br />
                  You may not use our services for any illegal or unauthorized
                  purposes. You may not upload or share any content that
                  violates any intellectual property rights or any laws or
                  regulations. You may not attempt to gain unauthorized access
                  to our systems or disrupt our services in any way.
                </p>

                <p>
                  <b>Fees and Payment</b>
                  <br />
                  We offer various pricing plans for our services, and you agree
                  to pay the fees associated with the plan you choose. You
                  authorize us to charge your credit card or other payment
                  method for any fees associated with your account.
                </p>

                <p>
                  <b>Termination</b>
                  <br />
                  You may terminate your account at any time by contacting us.
                  We reserve the right to terminate your account if you violate
                  any of these terms of service.
                </p>

                <p>
                  <b>Disclaimer of Warranties</b>
                  <br />
                  We provide our services on an "as is" and "as available" basis
                  and make no warranties or representations of any kind, whether
                  express, implied, or statutory. We do not guarantee that our
                  services will be uninterrupted or error-free, and we disclaim
                  any liability for any harm caused by the use of our services.
                </p>

                <p>
                  <b>Limitation of Liability</b>
                  <br />
                  In no event shall our company or our affiliates, officers,
                  directors, employees, or agents be liable for any direct,
                  indirect, incidental, special, or consequential damages
                  arising out of or in connection with the use of our services.
                </p>

                <p>
                  <b>Indemnification</b>
                  <br />
                  You agree to indemnify and hold our company and our
                  affiliates, officers, directors, employees, and agents
                  harmless from any and all claims, damages, liabilities, costs,
                  and expenses arising out of or in connection with your use of
                  our services or your breach of these terms of service.
                </p>

                <p>
                  <b>Governing Law and Jurisdiction</b>
                  <br />
                  These terms of service shall be governed by and construed in
                  accordance with the laws of the jurisdiction in which our
                  company is registered. Any dispute arising out of or in
                  connection with these terms of service shall be subject to the
                  exclusive jurisdiction of the courts in that jurisdiction.
                </p>

                <p>
                  <b>Changes to Terms of Service</b>
                  <br />
                  We reserve the right to modify these terms of service at any
                  time. We will notify you of any changes by posting the revised
                  terms of service on our website. Your continued use of our
                  services after any such changes constitutes your acceptance of
                  the revised terms of service.
                </p>

                <p>
                  If you have any questions or concerns about these terms of
                  service, please contact us.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Terms;
