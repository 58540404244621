import React from "react";

const FeatureList = () => {
  return (
    <>
      <p>
        Welcome to our online document security company! We offer a range of
        solutions to help individuals and businesses keep their digital
        documents secure and easily accessible. Here are some of the key
        features that make our platform stand out:
      </p>

      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-4 col-12">
            <img
              src="/assets/img/features/security.png"
              className="img-fluid"
              alt="Secure Document Storage and Sharing"
            />
          </div>
          <div className="col-lg-8 col-12">
            <p>
              <b>Secure Document Storage and Sharing:</b>
            </p>
            <p>
              Our platform allows you to securely store and share your documents
              with others, while maintaining complete control over who has
              access to them. We use state-of-the-art encryption and access
              controls to ensure that your documents are protected at all times.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-8 col-12">
            <p>
              <b>Digital Signature Solutions:</b>
            </p>
            <p>
              Our digital signature solutions allow you to sign documents
              electronically, eliminating the need for paper-based signatures
              and streamlining document workflows. Our signature solutions are
              legally binding and easy to use, making it simple to get documents
              signed quickly and securely.
            </p>
          </div>
          <div className="col-lg-4 col-12">
            <img
              src="/assets/img/features/digital-signature.png"
              className="img-fluid"
              alt="Digital Signature Solutions"
            />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-4 col-12">
            <img
              src="/assets/img/features/document-verification.png"
              className="img-fluid"
              alt="Document Verification Services"
            />
          </div>
          <div className="col-lg-8 col-12">
            <p>
              <b>Document Verification Services:</b>
            </p>
            <p>
              Our verification services use advanced algorithms to ensure the
              authenticity of signed documents, providing peace of mind to both
              signers and recipients. You can easily verify the authenticity of
              a signed document by uploading it to our platform.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-8 col-12">
            <p>
              <b>Flexible Subscription Plans:</b>
            </p>
            <p>
              We offer flexible subscription plans to meet the needs and budget
              of our customers. Our plans are customizable and transparent,
              allowing you to choose the plan that best fits your needs and
              budget.
            </p>
          </div>
          <div className="col-lg-4 col-12">
            <img
              src="/assets/img/features/money.png"
              className="img-fluid"
              alt="Flexible Subscription Plans"
            />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-4 col-12">
            <img
              src="/assets/img/features/interface.png"
              className="img-fluid"
              alt="User-Friendly Interface"
            />
          </div>
          <div className="col-lg-8 col-12">
            <p>
              <b>User-Friendly Interface:</b>
            </p>
            <p>
              Our platform is user-friendly and intuitive, making it easy for
              anyone to get started with secure document management. Whether
              you're a beginner or an advanced user, our platform is designed to
              be easy to use and accessible from anywhere with an internet
              connection.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-8 col-12">
            <p>
              <b>Exceptional Customer Support:</b>
            </p>
            <p>
              We believe in providing exceptional customer support to ensure
              that our customers have the assistance they need to use our
              platform effectively. Our support team is available via phone,
              email, and live chat to answer any questions you may have.
            </p>
          </div>
          <div className="col-lg-4 col-12">
            <img
              src="/assets/img/features/customer-support.png"
              className="img-fluid"
              alt="Exceptional Customer Support"
            />
          </div>
        </div>
      </div>

      <p>
        In summary, our online document security company offers a range of
        solutions to help you keep your digital documents secure and accessible.
        Our platform is user-friendly, customizable, and highly secure, with
        flexible subscription plans and exceptional customer support to ensure
        that you get the most out of our platform. Contact us today to learn
        more about how we can help you manage your documents securely and
        effectively!
      </p>
    </>
  );
};
export default FeatureList;
/*
https://favpng.com/ is where all the images here were from
 */
