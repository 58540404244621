import React, { useCallback, useEffect, useRef, useState } from "react";
import { ApiURL } from "../config";
import { FormInput } from "cmation-formsbuilder";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Loading from "../components/layouts/Loader";

const Contact = () => {
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    // update
  }, [firstName, lastName, emailAddress, message]);

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
    }
  }, [successMessage]);

  const getToken = (data) => {
    fetch(`${ApiURL}/contact-token`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === true) {
          data.token = res.token;
          sendForm(data);
        } else {
          console.log("fail 1", res);
        }
      })
      .catch((err) => {
        console.log("authLogin Error", err);
      });
  };

  const sendForm = (data) => {
    fetch(`${ApiURL}/contact-form`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        setButtonLoading(false);

        if (res.status === true) {
          setFirstName("");
          setLastName("");
          setEmailAddress("");
          setMessage("");
          setErrors({});
          setSuccessMessage("Your message has been sent!");
        } else {
          console.log("fail 2", res);
        }
      })
      .catch((err) => {
        console.log("authLogin Error", err);
      });
  };

  const submitContactForm = (e) => {
    e.preventDefault();
    setButtonLoading(true);
    let error = false;
    let errorData = [];

    if (
      firstName === "" ||
      firstName === "undefined" ||
      firstName?.length < 3
    ) {
      errorData.firstName = "Please enter your first name";
      error = true;
    }

    if (lastName === "" || lastName === "undefined" || lastName?.length < 3) {
      errorData.lastName = "Please enter your last name";
      error = true;
    }

    if (
      emailAddress === "" ||
      emailAddress === "undefined" ||
      emailAddress?.length < 3
    ) {
      errorData.emailAddress = "Please enter your email address";
      error = true;
    }

    if (message === "" || message === "undefined" || message?.length < 3) {
      errorData.message = "Please enter a message";
      error = true;
    }

    if (!error) {
      let data = {
        firstName: firstName,
        lastName: lastName,
        emailAddress: emailAddress,
        message: message,
      };
      getToken(data);
    } else {
      setErrors(errorData);
      setButtonLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Simply Collaborate | Contact Us</title>
        <meta name="description" content="" />
        <meta name="robots" content="" />
        <meta name="googlebot" content="" />
        <meta name="keywords" content="" />
        <meta name="theme-color" content="#008f68" />
      </Helmet>
      <section
        className="py-0 overflow-hidden"
        id="banner"
        data-bs-theme="light"
      >
        <div
          className="bg-holder overlay"
          style={{
            backgroundImage: "url(/assets/img/generic/13.jpg)",
            backgroundPosition: "center bottom",
          }}
        />
        <div className="container pb-5">
          <div className="row flex-center pt-1 pt-lg-8 pb-lg-9 pb-xl-0">
            <div className="col-lg-4">
              <h1 className="text-white">Contact Us</h1>
            </div>
          </div>
          <div className="row flex-center">
            <div className="col-lg-4">
              <div className="card mb-3">
                <div className="card-body">
                  {successMessage && (
                    <div className="alert alert-success" role="alert">
                      <div className="alert-success">{successMessage}</div>
                    </div>
                  )}

                  <div className="container">
                    <form onSubmit={submitContactForm}>
                      <div className="row">
                        <FormInput
                          type="text"
                          name="firstName"
                          id="firstName"
                          label="First Name"
                          value={firstName}
                          colSize={2}
                          onChange={(value) => setFirstName(value)}
                          error={errors?.firstName ?? null}
                        />
                        <FormInput
                          type="text"
                          name="lastName"
                          id="lastName"
                          label="Last Name"
                          value={lastName}
                          colSize={2}
                          onChange={(value) => setLastName(value)}
                          error={errors?.lastName ?? null}
                        />
                        <FormInput
                          type="text"
                          name="emailAddress"
                          id="emailAddress"
                          label="Email Address"
                          value={emailAddress}
                          colSize={1}
                          onChange={(value) => setEmailAddress(value)}
                          error={errors?.emailAddress ?? null}
                        />
                        <FormInput
                          name="message"
                          id="message"
                          label="Message"
                          value={message}
                          colSize={1}
                          type="textarea"
                          onChange={(value) => setMessage(value)}
                          error={errors?.message ?? null}
                        />
                      </div>
                      <div className="row">
                        <div className="col-12 py-3">
                          {buttonLoading ? (
                            <div
                              className="spinner-border text-warning"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          ) : (
                            <button
                              className="btn btn-sm btn-success"
                              onClick={submitContactForm}
                            >
                              Send Message
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Contact;
