import React from "react";
import classnames from "classnames";
import InputError from "./InputError";
import InputHelpText from "./InputHelpText";

const FormInputRadio = (props) => {
  const { name, value, label, onChange, error, defaultValue } = props;
  return (
    <div className={`col-12 align-bottom col-sm-${props.divColSize}`}>
      <div className="container p-0">
        <div className="form-group row border-bottom pb-2">
          <label
            className={classnames("col-sm-12 m-0 pt-2 pb-2", {
              "is-invalid": error,
            })}
          >
            {label}
          </label>

          <div className="col-sm-12">
            {props.options
              ? props.options.map((option, index) => {
                  let defaultChecked = null;

                  if (parseInt(value) === parseInt(option.id))
                    defaultChecked = "checked";
                  else if (value === option.id) defaultChecked = "checked";
                  else if (defaultValue === option.id && value === "")
                    defaultChecked = "checked";

                  return (
                    <div key={index} className="form-check form-check-inline">
                      <label
                        className="form-check-label"
                        htmlFor={name + index}
                      >
                        <input
                          className={classnames("form-check-input", {
                            "is-invalid": error,
                          })}
                          type="radio"
                          defaultChecked={defaultChecked}
                          name={name}
                          id={name + index}
                          value={option.id}
                          onChange={(e) => onChange(e.target.value)}
                        />
                        {option.name}
                      </label>
                    </div>
                  );
                })
              : null}
          </div>

          <InputError error={props.error ?? ""} />
          <InputHelpText helpText={props.helpText ?? ""} />
        </div>
      </div>
    </div>
  );
};

export default FormInputRadio;
