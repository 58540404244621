import React from "react";
import { Link, Outlet } from "react-router-dom";

const ProfileNav = ({ children }) => {
  return (
    <section className="py-0 overflow-hidden" id="banner" data-bs-theme="light">
      <div
        className="bg-holder overlay"
        style={{
          backgroundImage: "url(/assets/img/generic/13.jpg)",
          backgroundPosition: "center bottom",
        }}
      />
      <div className="container flex-center pt-1 pt-lg-8 pb-lg-9 pb-xl-0">
        <div className="row g-1 mb-5">
          <div className="col-3">
            <div className="card">
              <div className="card-body">
                <h5>Account Links</h5>
                <nav className="navbar navbar-expand-lg bg-body-tertiary">
                  <ul>
                    <li className="nav-item">
                      <Link to="/account/" className="nav-link">
                        Dashboard
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/account/password" className="nav-link">
                        Password
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/account/profile" className="nav-link">
                        Personal Info
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/account/billing" className="nav-link">
                        Billing Info
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/account/subscription" className="nav-link">
                        Subscription
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/account/support" className="nav-link">
                        Contact Support
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/account/workspace" className="nav-link">
                        My Workspace
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/auth/logout" className="nav-link">
                        Logout
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div className="col-9">
            <Outlet />
            {children}
          </div>
        </div>
      </div>
    </section>
  );
};
export default ProfileNav;
