import { Link } from "react-router-dom";

const LayoutNav = () => {
  return (
    <nav
      className="navbar navbar-standard navbar-expand-lg fixed-top navbar-dark bg-dark"
      data-navbar-darken-on-scroll="data-navbar-darken-on-scroll"
    >
      <div className="container">
        <Link className="navbar-brand" to={"/"}>
          <span className="text-white dark__text-white">SimplyCollaborate</span>
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarStandard"
          aria-controls="navbarStandard"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse scrollbar" id="navbarStandard">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link" to={"/"} role="button" id="dashboards">
                Home
              </Link>
            </li>
            <li className="nav-item ">
              <Link
                className="nav-link"
                to={"/product/features"}
                role="button"
                id="apps"
              >
                Features
              </Link>
            </li>
            <li className="nav-item ">
              <Link
                className="nav-link"
                to={"/product/pricing"}
                role="button"
                id="apps"
              >
                Prices
              </Link>
            </li>
            <li className="nav-item ">
              <Link
                className="nav-link"
                to={"/page/customers"}
                role="button"
                id="apps"
              >
                Customers
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to={"/page/faqs"}
                role="button"
                id="apps"
              >
                FAQs
              </Link>
            </li>
          </ul>
          <ul className="navbar-nav navbar-nav-icons ms-auto flex-row align-items-center">
            <li className="nav-item">
              <Link className="nav-link pe-0 ps-2" to="/account">
                <div className="avatar avatar-xl">
                  <img
                    className="rounded-circle bg-white"
                    src="/assets/img/team/avatar.png"
                    alt="View Account"
                  />
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
export default LayoutNav;
