import React from "react";
import FeatureList from "./FeatureList";

const Features = () => {
  return (
    <section className="py-0 overflow-hidden" id="banner" data-bs-theme="light">
      <div
        className="bg-holder overlay"
        style={{
          backgroundImage: "url(/assets/img/generic/bg-2.jpg)",
          backgroundPosition: "center bottom",
        }}
      />
      <div className="container">
        <div className="row flex-center pt-1 pt-lg-8 pb-lg-9 pb-xl-0">
          <div className="col-12">
            <h1 className="text-white text-center">Product Features</h1>
          </div>
          <div className="col-12">
            <div className="card mb-3">
              <div className="card-body p-5">
                <FeatureList />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Features;
/*
https://favpng.com/ is where all the images here were from
 */
