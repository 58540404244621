import React, { useCallback, useEffect, useState } from "react";
import { ApiURL, auth } from "../../config";
import { Helmet } from "react-helmet";
import { FormInput } from "cmation-formsbuilder";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/layouts/Loader";

const Support = () => {
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [message, setMessage] = useState("");
  const token = localStorage.getItem("token");

  const navigate = useNavigate();

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
    }
  }, [successMessage]);

  const checkAuth = (data) => {
    fetch(`${ApiURL}/auth/user`, {
      method: "GET",
      headers: { "Content-Type": "application/json", Authorization: token },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.status === true) {
          setFirstName(res.userInfo.firstName);
          setLastName(res.userInfo.lastName);
          setEmailAddress(res.userInfo.username);
        } else {
          if (auth) {
            localStorage.removeItem("token");
            navigate("/auth/login");
          }
        }
      })
      .catch((err) => {
        console.log("authLogin Error", err);
      });
  };

  useEffect(() => {
    checkAuth();
  }, []);

  const sendForm = (data) => {
    fetch(`${ApiURL}/support-form`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        setButtonLoading(false);
        if (res.status === true) {
          setMessage("");
          setErrors({});
          setSuccessMessage("Your message has been sent!");
        } else {
          console.log("fail 2", res);
        }
      })
      .catch((err) => {
        console.log("authLogin Error", err);
      });
  };

  const submitContactForm = useCallback((e) => {
    e.preventDefault();
    setButtonLoading(true);
    let error = false;
    let errorData = [];

    if (
      firstName === "" ||
      firstName === "undefined" ||
      firstName?.length < 3
    ) {
      errorData.firstName = "Please enter your first name";
      error = true;
    }

    if (lastName === "" || lastName === "undefined" || lastName?.length < 3) {
      errorData.lastName = "Please enter your last name";
      error = true;
    }

    if (
      emailAddress === "" ||
      emailAddress === "undefined" ||
      emailAddress?.length < 3
    ) {
      errorData.emailAddress = "Please enter your email address";
      error = true;
    }

    if (message === "" || message === "undefined" || message?.length < 3) {
      errorData.message = "Please enter a message";
      error = true;
    }

    if (!error) {
      let data = {
        firstName: firstName,
        lastName: lastName,
        emailAddress: emailAddress,
        message: message,
      };
      sendForm(data);
    } else {
      setButtonLoading(false);
      setErrors(errorData);
    }
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Simply Collaborate | Contact Support</title>
        <meta name="description" content="" />
        <meta name="robots" content="" />
        <meta name="googlebot" content="" />
        <meta name="keywords" content="" />
        <meta name="theme-color" content="#008f68" />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="card mb-3">
              <div className="card-body">
                <h1>Contact Support</h1>

                {successMessage && (
                  <div className="alert alert-success" role="alert">
                    <div className="alert-success">{successMessage}</div>
                  </div>
                )}

                <div className="container">
                  <form onSubmit={submitContactForm}>
                    <div className="row">
                      <FormInput
                        type={"display"}
                        name="firstName"
                        id="firstName"
                        label="First Name"
                        value={firstName}
                        error={errors?.firstName ?? null}
                        colSize={2}
                        onChange={(value) => setFirstName(value)}
                      />
                      <FormInput
                        type={"display"}
                        name="lastName"
                        id="lastName"
                        label="Last Name"
                        value={lastName}
                        error={errors?.lastName ?? null}
                        colSize={2}
                        onChange={(value) => setLastName(value)}
                      />
                      <FormInput
                        type={"display"}
                        name="emailAddress"
                        id="emailAddress"
                        label="Email Address"
                        value={emailAddress}
                        error={errors?.emailAddress ?? null}
                        colSize={1}
                        onChange={(value) => setEmailAddress(value)}
                      />
                      <FormInput
                        name="message"
                        id="message"
                        label="Message"
                        type="textarea"
                        value={message}
                        error={errors?.message ?? null}
                        colSize={1}
                        onChange={(value) => setMessage(value)}
                      />
                    </div>
                    <div className="row">
                      <div className="col-12 py-3">
                        {buttonLoading ? (
                          <div
                            className="spinner-border text-warning"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          <button
                            className="btn btn-sm btn-success"
                            onClick={submitContactForm}
                          >
                            Send Message
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Support;
