import React from "react";
const Privacy = () => {
  return (
    <section className="py-0 overflow-hidden" id="banner" data-bs-theme="light">
      <div
        className="bg-holder overlay"
        style={{
          backgroundImage: "url(/assets/img/generic/20.jpg)",
          backgroundPosition: "center bottom",
        }}
      />
      <div className="container">
        <div className="row flex-center pt-1 pt-lg-8 pb-lg-9 pb-xl-0">
          <div className="col-12">
            <h1 className="text-white text-center">Privacy Policy</h1>
          </div>
          <div className="col-12">
            <div className="card mb-3">
              <div className="card-body p-5">
                <p>
                  Thank you for choosing our company for your online document
                  security needs. We take your privacy seriously and are
                  committed to protecting your personal information. This
                  privacy policy explains how we collect, use, and protect your
                  personal information when you use our services.
                </p>

                <p>
                  <b>Information We Collect</b>
                  <br />
                  We collect various types of information when you use our
                  services, including:
                </p>
                <ul>
                  <li>
                    Personal information: We may collect your name, email
                    address, and other contact information when you register for
                    an account with us.
                  </li>
                  <li>
                    User content: We may collect the content you upload, store,
                    and share through our services.
                  </li>
                  <li>
                    Usage information: We may collect information about your use
                    of our services, including the type of device you use, your
                    IP address, and other usage data.
                  </li>
                  <li>
                    Cookies and similar technologies: We may use cookies and
                    similar technologies to collect information about your use
                    of our website and services.
                  </li>
                </ul>
                <p>
                  <b>How We Use Your Information</b>
                  <br />
                  We use the information we collect to provide and improve our
                  services, to communicate with you about your account and our
                  services, and to comply with legal requirements. Specifically,
                  we may use your information to:
                </p>

                <ul>
                  <li>
                    Provide and improve our services: We use your information to
                    provide our online document security solutions and to
                    improve the functionality and security of our services.
                  </li>
                  <li>
                    Communicate with you: We may use your information to
                    communicate with you about your account, updates to our
                    services, and other important information related to our
                    services.
                  </li>
                  <li>
                    Comply with legal requirements: We may use your information
                    to comply with applicable laws and regulations, including
                    responding to legal requests and protecting our rights and
                    property.
                  </li>
                </ul>

                <p>
                  <b>How We Protect Your Information</b>
                  <br />
                  We take appropriate measures to protect your personal
                  information from unauthorized access, disclosure, or misuse.
                  We use industry-standard security measures to safeguard your
                  personal information, including secure data storage,
                  encryption, and access controls. We also require our employees
                  and contractors to comply with strict confidentiality
                  obligations.
                </p>

                <p>
                  <b>Sharing Your Information</b>
                  <br />
                  We do not sell, rent, or share your personal information with
                  third parties, except as described in this privacy policy. We
                  may share your information with our service providers, such as
                  our hosting provider and payment processor, to provide our
                  services to you. We may also share your information if
                  required by law or in response to a legal request.
                </p>

                <p>
                  <b>Your Choices</b>
                  <br />
                  You have choices about how we collect, use, and share your
                  personal information. You may update your account information
                  at any time by logging into your account. You may also
                  unsubscribe from our marketing communications at any time by
                  clicking the "unsubscribe" link in our emails.
                </p>

                <p>
                  <b>Children's Privacy</b>
                  <br />
                  Our services are not intended for children under the age of
                  13. We do not knowingly collect personal information from
                  children under the age of 13. If we become aware that we have
                  collected personal information from a child under the age of
                  13, we will take steps to delete the information as soon as
                  possible.
                </p>

                <p>
                  <b>Changes to This Privacy Policy</b>
                  <br />
                  We reserve the right to modify this privacy policy at any
                  time. We will notify you of any changes by posting the revised
                  privacy policy on our website. Your continued use of our
                  services after any such changes constitutes your acceptance of
                  the revised privacy policy.
                </p>

                <p>
                  If you have any questions or concerns about this privacy
                  policy, please contact us.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Privacy;
