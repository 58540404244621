import React from "react";
import classnames from "classnames";
import InputError from "./InputError";
import InputHelpText from "./InputHelpText";

const FormInputCheckbox = (props) => {
  let value = "";
  if (typeof props.value !== "undefined") value = props.value.toString();
  return (
    <div className={`col-12 align-bottom col-sm-${props.divColSize}`}>
      <div className="container p-0">
        <div className="form-group row border-bottom pb-2">
          <label
            htmlFor={props.name}
            className={classnames("col-sm-12 m-0 pt-2 pb-2", {
              "is-invalid": props.error,
            })}
          >
            {props.label}
          </label>
          <div className="col-sm-12">
            {props.options.map((option) => {
              let checked = false;
              if (
                (typeof option.id !== "undefined" &&
                  value.indexOf("," + option.id.toString() + ",") !== -1) ||
                value === option.id ||
                (typeof option.value !== "undefined" &&
                  value.indexOf("," + option.value.toString() + ",") !== -1) ||
                value === option.value
              ) {
                checked = true;
              }

              return (
                <div
                  key={option.id ? option.id : option.name}
                  className="form-check form-check-inline"
                >
                  <label
                    className="form-check-label"
                    htmlFor={`option${option.id ? option.id : option.name}`}
                  >
                    <input
                      type="checkbox"
                      id={`option${option.id ? option.id : option.name}`}
                      name={props.name}
                      value={option.value}
                      checked={checked}
                      className="form-check-input"
                      onChange={() =>
                        props.onChange(option.id ? option.id : option.value)
                      }
                    />
                    {option.name}
                  </label>
                </div>
              );
            })}
            <InputError error={props.error} />
            <InputHelpText helpText={props.helpText} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormInputCheckbox;
