import React from "react";
import classnames from "classnames";
import PasswordStrengthMeter from "./PasswordStrengthMeter";
import InputError from "./InputError";
import InputHelpText from "./InputHelpText";

const FormInputPassword = (props) => {
  const changeValue = (value) => {
    props.onChange(value);
  };

  if (props.floatingLabel) {
    return (
      <div className={`col-12 align-bottom col-sm-${props.divColSize}`}>
        <div className="form-floating mb-3">
          <input
            ref={props.refProp}
            id={props.name}
            name={props.name}
            type={props.type}
            value={props.password}
            autoComplete={props.autoComplete ? props.autoComplete : "off"}
            className={classnames(props.inputSizeClass, {
              "is-invalid": props.error,
            })}
            onBlur={props.onBlur}
            onChange={(e) => props.onChange(e.target.value)}
            aria-describedby={props.name + "Help"}
            placeholder={props.placeholder}
          />
          <label
            htmlFor={props.name}
            className={classnames("", {
              "is-invalid": props.error,
            })}
          >
            {props.label}
          </label>
          {props.children}
          <InputError error={props.error} />
          <InputHelpText helpText={props.helpText} />
        </div>
      </div>
    );
  }

  return (
    <div className={`col-12 align-bottom col-sm-${props.divColSize}`}>
      <div
        className={`${props.floatingLabelClass} row ${
          !props.hideBorder ? "border-bottom" : ""
        } pb-2`}
      >
        <label
          htmlFor={props.name}
          className={classnames("col-sm-12 m-0 pt-2 pb-2", {
            "is-invalid": props.error,
          })}
        >
          {props.label}
        </label>
        <div className="col-sm-12">
          <input
            id={props.name}
            name={props.name}
            type="password"
            value={props.password}
            autoComplete={props.autoComplete ? props.autoComplete : "off"}
            className={classnames(props.inputSizeClass, {
              "is-invalid": props.error,
            })}
            onChange={(e) => props.onChange(e.target.value)}
            onBlur={props.onBlur}
            aria-describedby={props.name + "Help"}
            placeholder={props.placeholder}
          />
          {props.children && (
            <>
              <br />
              {props.children}
              <br />
            </>
          )}
          <InputError error={props.error} />
          {!props.hideMeter && (
            <PasswordStrengthMeter password={props.password} />
          )}
        </div>
      </div>
      <InputHelpText helpText={props.helpText} />
    </div>
  );
};

export default FormInputPassword;
