import React from "react";

const Blank = () => {
  return (
    <section className="py-0 overflow-hidden" id="banner" data-bs-theme="light">
      <div
        className="bg-holder overlay"
        style={{
          backgroundImage: "url(/assets/img/generic/13.jpg)",
          backgroundPosition: "center bottom",
        }}
      />
      <div className="container">
        <div className="row flex-center pt-1 pt-lg-8 pb-lg-9 pb-xl-0">
          <div className="col-12">
            <h1 className="text-white">About Us</h1>
          </div>
          <div className="col-12">
            <div className="card mb-3">
              <div className="card-body p-5">
                <img
                  src={"/assets/images/denver.jpg"}
                  alt="Colorado"
                  className=" rounded float-end mx-5"
                  width="500"
                />
                <p>
                  Welcome to Simply Collaborate, the leading provider of
                  cutting-edge online document security solutions. With a
                  passion for safeguarding sensitive information in the digital
                  realm, we are committed to delivering top-quality services
                  that offer peace of mind to individuals and businesses alike.
                </p>
                <p>
                  At Simply Collaborate, we understand the growing importance of
                  protecting confidential documents in today's fast-paced and
                  interconnected world. With cyber threats on the rise, our
                  mission is to provide robust and user-friendly solutions that
                  empower our customers to keep their digital assets secure.
                </p>
                <p>
                  With a wealth of industry expertise, our dedicated team has
                  worked tirelessly over the past [X years] to develop a
                  comprehensive suite of document security services. We combine
                  advanced encryption technology, stringent access controls, and
                  best-in-class security measures to ensure the utmost
                  protection for your valuable data.
                </p>
                <p>
                  What sets us apart is our unwavering commitment to customer
                  satisfaction. We prioritize user experience and continuously
                  strive to make our platform as intuitive and user-friendly as
                  possible. We believe that effective document security should
                  not be complicated, and we work diligently to provide a
                  seamless and hassle-free experience for our customers.
                </p>
                <p>
                  We pride ourselves on delivering exceptional customer support.
                  Our knowledgeable and friendly team is always ready to assist
                  you with any questions or concerns you may have. We value
                  transparency and open communication, and we believe that
                  building strong relationships with our customers is paramount
                  to our success.
                </p>
                <p>
                  Whether you are an individual looking to secure personal
                  documents or a business in need of comprehensive data
                  protection, Simply Collaborate is here to serve you. Join our
                  growing community of satisfied customers and experience the
                  confidence that comes with knowing your sensitive documents
                  are safe with us.
                </p>
                <p>
                  Thank you for choosing Simply Collaborate. We look forward to
                  serving you and exceeding your expectations with our
                  state-of-the-art online document security solutions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Blank;
