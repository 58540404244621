import React, { useEffect, useReducer, useState } from "react";
import { Helmet } from "react-helmet";
import { FormInput } from "cmation-formsbuilder";
import { ApiURL } from "../../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/fontawesome-free-solid";
import { useNavigate } from "react-router-dom";

function SignUpWorkspace({ processSubscription, data }) {
  const [error, setError] = useState([]);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const navigate = useNavigate();

  const [workspaceSC, setWorkspaceSC] = useState(data.workspace || "");
  const [workspaceCheckSuccess, setWorkspaceCheckSuccess] = useState(false);
  const [workspaceCheckError, setWorkspaceCheckError] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [aliasDomain] = useState("");

  const checkForErrors = () => {
    setWorkspaceCheckSuccess(false);
    setWorkspaceCheckError(false);

    let errorTMP = error;
    let checkString = /^[a-zA-Z]+$/.test(workspaceSC);
    if (!workspaceSC) {
      errorTMP["workspace"] = "Your workspace name is too short";
      setError(errorTMP);
      setWorkspaceCheckSuccess(false);
      setWorkspaceCheckError(true);
      return true;

    } else if (!companyName) {
      errorTMP["workspace"] = "";
      errorTMP["companyName"] = "Please enter your company name";
      setError(errorTMP);
      return true;
    }
    return false;
  };

  const continueSignup = () => {
    console.log("continueSignup");
    if (!checkForErrors()) {
      processSubscription(1, {
        workspace: workspaceSC,
        companyName,
        aliasDomain,
      });
    }
  };

  const checkWorkspace = () => {
    if (!checkForErrors()) {
      setError([]);
      fetch(`${ApiURL}/subscription/checkWorkspace`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ workspaceSC }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (!res.status) {
            let errorTMP = error;
            errorTMP["username"] = res.message;
            setError(errorTMP);

            setWorkspaceCheckSuccess(false);
            setWorkspaceCheckError(true);

            forceUpdate();
          }
          if (res.status) {
            setWorkspaceCheckSuccess(true);
            setWorkspaceCheckError(false);
          }
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    console.log("error", error, workspaceSC, companyName, aliasDomain);
  }, [error]);

  return (
    <>
      <Helmet>
        <title>Simply Collaborate | Select Workspace</title>
        <meta name="description" content="" />
        <meta name="robots" content="" />
        <meta name="googlebot" content="" />
        <meta name="keywords" content="" />
        <meta name="theme-color" content="#008f68" />
      </Helmet>

      <div className="container">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="row">
            <div className="col-12">
              <h2>Workspace Name</h2>
              <p>
                This will be the url you and your clients will log in to
                collaborate. All URLs are reviewed prior to creating the
                workspace.
              </p>
            </div>
            <FormInput
              type="text"
              id="workspaceSC"
              name="workspaceSC"
              value={workspaceSC}
              label="Workspace"
              colSize={1}
              placeholder="workspace"
              onBlur={checkWorkspace}
              onChange={(value) => setWorkspaceSC(value)}
              error={error["workspace"]}
              helpText="This is the name of your workspace."
            >
              <span className="input-group-text" id="basic-addon2">
                .simplycollaborate.com
              </span>
              {workspaceCheckSuccess && (
                <span className="input-group-text text-success">
                  <FontAwesomeIcon icon={faCheckCircle} size="2x" />
                </span>
              )}
              {workspaceCheckError && (
                <span className="input-group-text text-danger">
                  <FontAwesomeIcon icon={faTimesCircle} size="2x" />
                </span>
              )}
            </FormInput>
            <FormInput
              type="text"
              id="companyName"
              name="companyName"
              value={companyName}
              label="Company Name"
              placeholder="Company Name"
              onBlur={checkWorkspace}
              colSize={1}
              onChange={(value) => setCompanyName(value)}
              error={error["companyName"]}
              helpText="This is the name of your company."
            />
          </div>

          <div className="row py-3 justify-content-center">
            <div className="col-6">
              <button
                type={"button"}
                className="btn btn-sm btn-secondary"
                onClick={() => navigate("/product/pricing")}
              >
                Cancel
              </button>
            </div>
            <div className="col-6 text-end">
              <button
                type={"submit"}
                className="btn btn-sm btn-primary"
                onClick={continueSignup}
              >
                Continue
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default SignUpWorkspace;
