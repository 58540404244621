import React from "react";
import classnames from "classnames";
import InputError from "./InputError";
import InputHelpText from "./InputHelpText";

const FormInputList = (props) => {
  if (
    (props.value === "" || typeof props.value === "undefined") &&
    props.defaultValue !== "" &&
    typeof props.defaultValue !== "undefined"
  ) {
    props.value = props.defaultValue;
  }

  return (
    <div className={`col-12 align-bottom col-sm-${props.divColSize}`}>
      <div className="container p-0">
        <div className="form-group row border-bottom pb-2">
          <label
            htmlFor={props.name}
            className={classnames("col-sm-12 m-0 pt-2 pb-2", {
              "is-invalid": props.error,
            })}
          >
            {props.label}
          </label>
          <input
            className={classnames(props.inputSizeClass, {
              "is-invalid": props.error,
            })}
            list={`${props.name}List`}
            name={props.name}
            id={props.id ? props.id : props.name}
            value={props.value}
            placeholder={props.placeholder}
            onChange={(e) => props.onChange(e.target.value)}
          />
          <div className="col-sm-12">
            <datalist id={`${props.name}List`}>
              {props.options?.length > 0 &&
                props.options.map((option, i) => {
                  return (
                    <option key={`option_${i}`} value={option.id}>
                      {option.name}
                    </option>
                  );
                })}
            </datalist>
          </div>
          <InputError error={props.error} />
          <InputHelpText helpText={props.helpText} />
        </div>
      </div>
    </div>
  );
};

export default FormInputList;
