import React from "react";
import { Link } from "react-router-dom";

const LayoutFooter = () => {
  return (
    <>
      <section className="bg-dark pt-4 pb-4" data-bs-theme="light">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <h5 className="text-uppercase text-white opacity-85 mb-3">
                Our Mission
              </h5>
              <p className="text-600">
                Our company is dedicated to providing top-quality online
                document security solutions to our customers. We believe that
                every individual and business should have access to reliable and
                secure document storage, and we strive to make this a reality
                through our services. Our mission is to protect our customers'
                confidential information by utilizing the latest technology and
                industry best practices, so they can focus on what matters most:
                their work.
              </p>
              <div className="icon-group mt-4">
                <a className="icon-item bg-white text-facebook" href="#!">
                  <span className="fab fa-facebook-f"></span>
                </a>
                <a className="icon-item bg-white text-twitter" href="#!">
                  <span className="fab fa-twitter"></span>
                </a>
                <a className="icon-item bg-white text-google-plus" href="#!">
                  <span className="fab fa-google-plus-g"></span>
                </a>
                <a className="icon-item bg-white text-linkedin" href="#!">
                  <span className="fab fa-linkedin-in"></span>
                </a>
                <a className="icon-item bg-white" href="#!">
                  <span className="fab fa-medium-m"></span>
                </a>
              </div>
            </div>
            <div className="col ps-lg-6 ps-xl-8">
              <div className="row mt-5 mt-lg-0">
                <div className="col-6 col-md-3">
                  <h5 className="text-uppercase text-white opacity-85 mb-3">
                    Company
                  </h5>
                  <ul className="list-unstyled">
                    <li className="mb-1">
                      <Link className="link-600" to="/page/about">
                        About
                      </Link>
                    </li>
                    <li className="mb-1">
                      <Link className="link-600" to="/page/contact">
                        Contact
                      </Link>
                    </li>
                    <li className="mb-1">
                      <Link className="link-600" to="/academy">
                        Academy
                      </Link>
                    </li>
                    <li className="mb-1">
                      <Link className="link-600" to="/docs/terms">
                        Terms
                      </Link>
                    </li>
                    <li className="mb-1">
                      <Link className="link-600" to="/docs/privacy">
                        Privacy
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-6 col-md-3">
                  <h5 className="text-uppercase text-white opacity-85 mb-3">
                    Product
                  </h5>
                  <ul className="list-unstyled">
                    <li className="mb-1">
                      <Link className="link-600" to="/product/features">
                        Features
                      </Link>
                    </li>
                    <li className="mb-1">
                      <Link className="link-600" to="/product/changelog">
                        Changelog
                      </Link>
                    </li>
                    <li className="mb-1">
                      <Link className="link-600" to="/product/pricing">
                        Pricing
                      </Link>
                    </li>
                    <li className="mb-1">
                      <Link className="link-600" to="/product/referral">
                        Referral Program
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col mt-5 mt-md-0">
                  <h5 className="text-uppercase text-white opacity-85 mb-3">
                    Collaborate Academy
                  </h5>
                  <ul className="list-unstyled">
                    <li>
                      <h5 className="fs-0 mb-0">
                        <Link
                          className="link-600"
                          to="/academy/how-to-create-an-organization"
                        >
                          How to create an organization
                        </Link>
                      </h5>
                      <p className="text-600 opacity-50">
                        Jan 15 &bull; 8min read{" "}
                      </p>
                    </li>
                    <li>
                      <h5 className="fs-0 mb-0">
                        <Link
                          className="link-600"
                          to="/academy/how-to-invite-a-user"
                        >
                          How to Invite a user
                        </Link>
                      </h5>
                      <p className="text-600 opacity-50">
                        Jan 5 &bull; 3min read &starf;
                      </p>
                    </li>
                    <li>
                      <h5 className="fs-0 mb-0">
                        <Link
                          className="link-600"
                          to="/academy/how-to-upload-a-file"
                        >
                          How to Upload a file
                        </Link>
                      </h5>
                      <p className="text-600 opacity-50">
                        Dec 25 &bull; 2min read
                      </p>
                    </li>
                    <li>
                      <h5 className="fs-0 mb-0">
                        <Link
                          className="link-600"
                          to="/academy/customizing-your-email-templates"
                        >
                          Customizing your email templates
                        </Link>
                      </h5>
                      <p className="text-600 opacity-50">
                        Dec 23 &bull; 10min read{" "}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-0 bg-dark" data-bs-theme="light">
        <div>
          <hr className="my-0 text-600 opacity-25" />
          <div className="container py-3">
            <div className="row justify-content-between fs--1">
              <div className="col-12 col-sm-auto text-center">
                <p className="mb-0 text-600 opacity-85">
                  2023 &copy; SimplyCollaborate.com
                </p>
              </div>
              <div className="col-12 col-sm-auto text-center">&nbsp;</div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default LayoutFooter;
