import React from "react";
import { Outlet } from "react-router-dom";

const AuthLayout = ({ children }) => {
  return (
    <main className="main" id="top">
      <Outlet />
      {children}
    </main>
  );
};
export default AuthLayout;
