import React from "react";
const Blank = () => {
  return (
    <section className="py-0 overflow-hidden" id="banner" data-bs-theme="light">
      <div
        className="bg-holder overlay"
        style={{
          backgroundImage: "url(/assets/img/generic/bg-1.jpg)",
          backgroundPosition: "center bottom",
        }}
      />
      <div className="container">
        <div className="row flex-center pt-1 pt-lg-8 pb-lg-9 pb-xl-0">
          <div className="col-12">
            <h1 className="text-white text-center">Latest Release Notes</h1>
          </div>
          <div className="col-12">
            <div className="card mb-3">
              <div className="card-body p-5">
                <p>
                  v3.0.2
                  <br />
                  * Added move file functionality within client folders
                  <br />
                  * Resend invite to new user from the dashboard
                  <br />
                  * Bug fixes
                  <br />
                </p>
                <p>
                  v3.0.1
                  <br />
                  * Ability to invite users from client management screen
                  <br />
                  * View client disk space usage from client documents screen
                  <br />
                </p>
                <p>
                  v3.0.0
                  <br />
                  * Favorite a file on the client folder screens
                  <br />
                  * View favorites on client dashboard
                  <br />
                  * View favorites on main dashboard
                  <br />
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Blank;
