import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";
import { ApiURL, appId, locationId } from "../../../config";

let parsePrice = require("parse-price");

function SignUpPayment({ processSubscription, backStep, price, discountCode }) {
  const [amount, setAmount] = useState(price || "");
  const [originalAmount] = useState(price || "");
  const continueSignup = (response) => {
    processSubscription(6, {
      nonce: response.token,
    });
  };

  const processDiscount = (discountCode) => {
    fetch(`${ApiURL}/subscription/checkDiscountCode`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ discountCode }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status) {
          let amountTMP = 0;
          if (res.discountType === "percentage") {
            let percentage = parseInt(res.discountAmount) / 100;
            let discountAmount = originalAmount * percentage;
            amountTMP = originalAmount - discountAmount;
          }
          if (res.discountType === "value") {
            //discountAmount
            amountTMP = originalAmount - parseInt(res.discountAmount);
          }
          setAmount(amountTMP);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (discountCode) {
      processDiscount(discountCode);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Simply Collaborate | Account Payment Method</title>
        <meta name="description" content="" />
        <meta name="robots" content="" />
        <meta name="googlebot" content="" />
        <meta name="keywords" content="" />
        <meta name="theme-color" content="#008f68" />
      </Helmet>
      <div className="container subscriptionHeader">
        <div className="row">
          <div className="col-12 text-white">
            <h1 className="text-center py-3">Enter your Payment information</h1>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center pb-4">
                  {originalAmount !== amount && (
                    <h4>
                      Original Amount : ${parsePrice(originalAmount / 100)} a
                      month
                    </h4>
                  )}
                  <h4>
                    {originalAmount !== amount ? "Discounted Price: " : ""}$
                    {parsePrice(amount / 100)} a month
                  </h4>
                </div>
                <PaymentForm
                  applicationId={appId}
                  cardTokenizeResponseReceived={(response, verifiedBuyer) => {
                    continueSignup(response);
                  }}
                  createPaymentRequest={() => ({
                    countryCode: "US",
                    currencyCode: "USD",
                    total: {
                      amount: { price },
                      label: "Total",
                    },
                  })}
                  locationId={locationId}
                >
                  <CreditCard />
                </PaymentForm>
              </div>
              <div className="row justify-content-center">
                <div className="col-12 pt-4">
                  <button
                    className="btn btn-sm btn-secondary"
                    onClick={backStep}
                  >
                    Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUpPayment;
