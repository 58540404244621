import React, { useEffect, useState } from "react";
import { ApiURL, auth } from "../../config";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/layouts/Loader";
import DashboardStats from "./Dashboard/Stats";

const Dashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("token");

  const checkAuth = () => {
    fetch(`${ApiURL}/auth/user`, {
      method: "GET",
      headers: { "Content-Type": "application/json", Authorization: token },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === true) {
          setLoading(false);
        } else {
          if (auth) {
            localStorage.removeItem("token");
            navigate("/auth/login");
          }
        }
      })
      .catch((err) => {
        console.log("authLogin Error", err);
      });
  };

  useEffect(() => {
    checkAuth();
  }, []);

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="card mb-3">
            <div className="card-body">
              <h1>Dashboard</h1>
              stats here
              <DashboardStats />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
