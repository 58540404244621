import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SignUpUserInformation from "./SignUpUserInformation";
import SignUpPayment from "./SignUpPayment";
import SignUpCompleting from "./SignUpCompleting";
import { ApiURL } from "../../../config";
import SignUpWorkspace from "./SignUpWorkspace";
import SignUpPersonalInformation from "./SignUpPersonalInformation";
import SignUpBillingInformation from "./SignUpBillingInformation";
import SignUpReferral from "./SignUpReferral";
const SignUp = (props) => {
  const { subscriptionType, numberOfClients, amountOfDiskSpace } = useParams();

  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [data, setData] = useState({});
  const [paymentError, setPaymentError] = useState("");

  const [squareSubscriptionId, setSquareSubscriptionId] = useState(0);
  const [clientCost, setClientCost] = useState(0);
  const [diskSpaceCost, setDiskSpaceCost] = useState(0);
  const [subscriptionCost, setSubscriptionCost] = useState(0);
  const [price, setPrice] = useState(0);
  const [discountCode, setDiscountCode] = useState("");
  const [scrollToTopStep, setScrollToTopStep] = useState(0);

  const [subscriptionTypes, setSubscriptionTypes] = useState([]);
  const [numberOfClientsList, setNumberOfClientsList] = useState([]);
  const [amountOfDiskSpaceList, setAmountOfDiskSpaceList] = useState([]);
  const [loading, setLoading] = useState(true);

  const backStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const calculatePrice = () => {
    let clientCost = parseInt(
      numberOfClientsList.filter((nc) => nc.id === numberOfClients)[0]?.cost
    );
    setClientCost(clientCost);

    let diskSpaceCost = parseInt(
      amountOfDiskSpaceList.filter((ds) => ds.id === amountOfDiskSpace)[0]?.cost
    );
    setDiskSpaceCost(diskSpaceCost);

    let subscriptionCost = parseInt(
      subscriptionTypes.filter((sub) => sub.id === subscriptionType)[0]?.price
    );
    setSubscriptionCost(subscriptionCost);

    let selectedSubscriptionId = subscriptionTypes.filter(
      (sub) => sub.id === subscriptionType
    )[0]?.subscriptionId;
    setSquareSubscriptionId(selectedSubscriptionId);

    let price = subscriptionCost + clientCost + diskSpaceCost;

    setPrice(price * 100);
  };

  useEffect(() => {
    if (
      (subscriptionTypes.length > 0,
      numberOfClientsList.length > 0,
      amountOfDiskSpaceList.length > 0)
    ) {
      calculatePrice();
    }
  }, [subscriptionTypes, numberOfClientsList, amountOfDiskSpaceList]);

  useEffect(() => {
    // data
  }, [data]);

  const loadSubscriptionTypes = () => {
    fetch(ApiURL + "/subscription-types", {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (!json.status) {
          setLoading(false);
        } else {
          setLoading(false);
          setSubscriptionTypes(json.subscriptionTypes);
          setNumberOfClientsList(json.numberOfClients);
          setAmountOfDiskSpaceList(json.amountOfDiskSpace);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });

    // setSubscriptionTypes
  };

  useEffect(() => {
    if (subscriptionTypes.length === 0 && loading === true) {
      loadSubscriptionTypes();
    }
  }, [subscriptionTypes, loading]);

  useEffect(() => {
    if (currentStep !== scrollToTopStep) {
      window.scrollTo(0, 0);
      setScrollToTopStep(scrollToTopStep);
    }
  }, [currentStep]);

  const processSubscription = (step, value) => {
    if (step === 1) {
      let subscriptionId = subscriptionType;

      setData({
        ...data,
        workspace: value.workspace,
        aliasDomain: value.aliasDomain,
        companyName: value.companyName,
        squareSubscriptionId,
        subscriptionId,
        clientCost,
        diskSpaceCost,
        subscriptionCost,
        numberOfClients,
        amountOfDiskSpace,
        subscriptionType,
        amount: price,
      });
      setCurrentStep(2);
    }
    if (step === 2) {
      setCurrentStep(3);
      setData({
        ...data,
        username: value.username,
        password: value.password,
        confirmPassword: value.confirmPassword,
      });
    }
    if (step === 3) {
      setCurrentStep(4);
      setData({
        ...data,
        firstName: value.firstName,
        lastName: value.lastName,
        personalPhone: value.personalPhone,
        personalAddress1: value.personalAddress1,
        personalAddress2: value.personalAddress2,
        personalCity: value.personalCity,
        personalState: value.personalState,
        personalZip: value.personalZip,
      });
    }
    if (step === 4) {
      setCurrentStep(5);
      setData({
        ...data,
        sameAsPersonal: value.sameAsPersonal,
        billingFirstName: value.billingFirstName,
        billingLastName: value.billingLastName,
        billingPhone: value.billingPhone,
        billingAddress1: value.billingAddress1,
        billingAddress2: value.billingAddress2,
        billingCity: value.billingCity,
        billingState: value.billingState,
        billingZip: value.billingZip,
      });
    }
    if (step === 5) {
      setCurrentStep(6);
      setDiscountCode(value.discountCode);
      let tmpData = {
        ...data,
        discountCode: value.discountCode,
        referralCode: value.referralCode,
      };
      setData(tmpData);
    }
    if (step === 6) {
      setCurrentStep(7);
      let tmpData = {
        ...data,
        nonce: value.nonce,
      };
      setData(tmpData);
      completeSubscription(tmpData);
    }

    if (step === 7) {
      completeSubscription();
    }
  };

  const cancelSubscription = () => {
    setCurrentStep(1);
    setData({});
  };

  const completeSubscription = (data = {}) => {
    fetch(`${ApiURL}/subscription/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === true) {
          cancelSubscription();
          navigate("/auth/login");
        } else {
          // if fail show page with failure
          setCurrentStep(res.error);
          setError(res.errorField);
          if (res.Exception) {
            setPaymentError(res.Exception);
          }
        }
      })
      .catch((err) => {
        console.log("authLogin Error", err);
      });
  };

  return (
    <section className="py-0 overflow-hidden" id="banner" data-bs-theme="light">
      <div
        className="bg-holder overlay"
        style={{
          backgroundImage: "url(/assets/img/generic/13.jpg)",
          backgroundPosition: "center bottom",
        }}
      />

      <div className="container">
        <div className="row flex-center pt-1 pt-lg-8 pb-lg-9 pb-xl-0 m-sm-0 p-sm-0">
          <div className="col-12 text-white text-center">
            <h1 className="text-center text-white">Subscription Information</h1>
            <p>
              To subscribe to our services enter the subscription information
              below.
            </p>
          </div>
          <div className="col-lg-8 col-12 m-sm-0 p-sm-0 m-md-4">
            <div className="card theme-wizard mb-5 m-sm-0 p-sm-0" id="wizard">
              <div className="card-header bg-light m-sm-0 p-sm-0">
                <ul className="nav justify-content-between nav-wizard">
                  <NavLink
                    link={{
                      title: "Workspace",
                      active: currentStep === 1,
                      icon: "lock",
                    }}
                  />
                  <NavLink
                    link={{
                      title: "Account",
                      active: currentStep === 2,
                      icon: "user",
                    }}
                  />
                  <NavLink
                    link={{
                      title: "Personal",
                      active: currentStep === 3,
                      icon: "file-invoice",
                    }}
                  />
                  <NavLink
                    link={{
                      title: "Billing",
                      active: currentStep === 4,
                      icon: "file-invoice-dollar",
                    }}
                  />
                  <NavLink
                    link={{
                      title: "Discount",
                      active: currentStep === 5,
                      icon: "tag",
                    }}
                  />
                  <NavLink
                    link={{
                      title: "Payment",
                      active: currentStep === 6,
                      icon: "dollar-sign",
                    }}
                  />
                  <NavLink
                    link={{
                      title: "Done",
                      active: currentStep === 7,
                      icon: "thumbs-up",
                    }}
                  />
                </ul>
              </div>
              <div className="card-body m-sm-0 p-sm-0 p-md-4">
                {currentStep === 1 && (
                  <SignUpWorkspace
                    processSubscription={processSubscription}
                    data={data}
                    screenError={error}
                  />
                )}
                {currentStep === 2 && (
                  <SignUpUserInformation
                    processSubscription={processSubscription}
                    data={data}
                    backStep={backStep}
                    screenError={error}
                  />
                )}
                {currentStep === 3 && (
                  <SignUpPersonalInformation
                    processSubscription={processSubscription}
                    data={data}
                    backStep={backStep}
                    screenError={error}
                  />
                )}
                {currentStep === 4 && (
                  <SignUpBillingInformation
                    processSubscription={processSubscription}
                    data={data}
                    backStep={backStep}
                    screenError={error}
                  />
                )}
                {currentStep === 5 && (
                  <SignUpReferral
                    processSubscription={processSubscription}
                    data={data}
                    backStep={backStep}
                    screenError={error}
                  />
                )}
                {currentStep === 6 && (
                  <>
                    {paymentError && (
                      <div className="alert alert-danger">{paymentError}</div>
                    )}
                    <SignUpPayment
                      processSubscription={processSubscription}
                      data={data}
                      price={price}
                      backStep={backStep}
                      screenError={error}
                      discountCode={discountCode}
                    />
                  </>
                )}
                {currentStep === 7 && (
                  <SignUpCompleting processSubscription={processSubscription} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default SignUp;

const NavLink = ({ link }) => {
  return (
    <li className="nav-item">
      <div className={`nav-link ${link.active ? "active" : ""} fw-semi-bold`}>
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <span className={`fas fa-${link.icon ? link.icon : ""}`}></span>
          </span>
        </span>
        <span
          className={`d-none ${
            link.active ? "text-dark" : ""
          } d-md-block mt-1 fs--1`}
        >
          {link.title}
        </span>
      </div>
    </li>
  );
};
