import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormInput } from "cmation-formsbuilder";
import { ApiURL } from "../config";
import Loader from "../components/layouts/Loader";

const PricingInfo = ({ subscriptionInfo, processUpgrade }) => {
  const navigate = useNavigate();
  const [subscriptionTypes, setSubscriptionTypes] = useState([]);
  const [numberOfClientsList, setNumberOfClientsList] = useState([]);
  const [amountOfDiskSpaceList, setAmountOfDiskSpaceList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [additionalPrice, setAdditionalPrice] = useState(0);
  const [originalCost, setOriginalCost] = useState(0);
  const [fetching, setFetching] = useState(false);

  const [originalAmountOfDiskSpace] = useState(
    subscriptionInfo?.amountOfDiskSpace
      ? subscriptionInfo?.amountOfDiskSpace
      : "B3A8A9F2-83C0-4D6D-AB27-3FF552CBFF4D"
  );
  const [amountOfDiskSpace, setAmountOfDiskSpace] = useState(
    subscriptionInfo?.amountOfDiskSpace
      ? subscriptionInfo?.amountOfDiskSpace
      : "B3A8A9F2-83C0-4D6D-AB27-3FF552CBFF4D"
  );

  const [originalNumberOfClients] = useState(
    subscriptionInfo?.numberOfClients
      ? subscriptionInfo?.numberOfClients
      : "C862B1FA-DDD3-4A7C-9968-B400DB392448"
  );
  const [numberOfClients, setNumberOfClients] = useState(
    subscriptionInfo?.numberOfClients
      ? subscriptionInfo?.numberOfClients
      : "C862B1FA-DDD3-4A7C-9968-B400DB392448"
  );

  const [subscriptionLevel] = useState(
    subscriptionInfo?.subscriptionTypeId
      ? subscriptionInfo?.subscriptionTypeId
      : 0
  );

  useEffect(() => {
    if (numberOfClients && amountOfDiskSpace) {
      updatePrice();
    }
  }, [
    numberOfClients,
    amountOfDiskSpace,
    numberOfClientsList,
    amountOfDiskSpaceList,
  ]);

  const updatePrice = () => {
    let clientCost = parseInt(
      numberOfClientsList.filter((client) => client.id === numberOfClients)[0]
        ?.cost
    );
    let diskSpaceCost = parseInt(
      amountOfDiskSpaceList.filter(
        (diskSpace) => diskSpace.id === amountOfDiskSpace
      )[0]?.cost
    );

    setAdditionalPrice(clientCost + diskSpaceCost);

    if (subscriptionInfo) {
      let originalClientCost = numberOfClientsList.filter(
        (client) => client.id === originalNumberOfClients
      )[0]?.cost;
      let originalDiskSpaceCost = amountOfDiskSpaceList.filter(
        (diskSpace) => diskSpace.id === originalAmountOfDiskSpace
      )[0]?.cost;
      let originalSubscriptionCost = subscriptionTypes.filter(
        (subscription) => subscription.id === subscriptionLevel
      )[0].startingPrice;
      setOriginalCost(
        originalClientCost + originalDiskSpaceCost + originalSubscriptionCost
      );
    }
  };

  const upgradeSubscription = (currentSubscriptionLevel) => {
    processUpgrade(
      currentSubscriptionLevel,
      numberOfClients,
      amountOfDiskSpace
    );
  };

  const purchaseSubscription = (subscriptionLevel) => {
    let path = "/product/signup/";

    path =
      path +
      subscriptionLevel +
      "/" +
      numberOfClients +
      "/" +
      amountOfDiskSpace;

    navigate(path);
  };

  const loadSubscriptionTypes = () => {
    setFetching(true);
    fetch(ApiURL + "/subscription-types", {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (!json.status) {
          setLoading(false);
        } else {
          setLoading(false);
          setSubscriptionTypes(json.subscriptionTypes);
          setNumberOfClientsList(json.numberOfClients);
          setAmountOfDiskSpaceList(json.amountOfDiskSpace);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });

    // setSubscriptionTypes
  };

  useEffect(() => {
    if (subscriptionTypes.length === 0 && loading === true && !fetching) {
      loadSubscriptionTypes();
    }
  }, [subscriptionTypes, loading]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {subscriptionTypes?.map((sub, index) => {
        return (
          <div
            className="col-12 col-lg-4 col-md-6 mb-4 mb-lg-0"
            key={`subscription${index}`}
          >
            <div
              className={`rounded-3 overflow-hidden mb-3 mb-md-0 ${
                subscriptionLevel === sub.id ? "borderPrimary" : ""
              }`}
            >
              <div className="bg-dark d-flex flex-between-center p-4">
                <div>
                  <h3 className="fw-light fs-5 mb-0 text-primary">
                    {sub.name}
                  </h3>
                  {subscriptionLevel !== sub.id && (
                    <h2 className="fw-light mt-0 text-primary">
                      <sup className="fs-1">$</sup>
                      <span className="fs-3">
                        {(
                          parseInt(sub.price) + parseInt(additionalPrice)
                        ).toString()}
                      </span>
                      <span className="fs--2 mt-1"> / mo</span>
                    </h2>
                  )}
                  {subscriptionLevel !== 0 && (
                    <h2 className="fw-light mt-0 text-primary">
                      {subscriptionLevel === sub.id &&
                        additionalPrice + parseInt(sub.price) !==
                          originalCost && (
                          <>
                            <sup className="fs-1">Original Cost $</sup>
                            <span className="fs-3">{originalCost}</span>
                            <span className="fs--2 mt-1"> / mo</span>
                            <br />
                          </>
                        )}
                      <sup className="fs-1">
                        {parseInt(sub.price) !== originalCost
                          ? "Upgrade Cost "
                          : ""}
                        $
                      </sup>
                      <span className="fs-3">
                        {parseInt(sub.price) + additionalPrice}
                      </span>
                      <span className="fs--2 mt-1"> / mo</span>
                    </h2>
                  )}
                </div>
              </div>
              <div className="p-4 bg-light">
                <FormInput
                  type="select"
                  id="numberOfClients"
                  name="numberOfClients"
                  label="Number of Clients"
                  value={numberOfClients}
                  options={numberOfClientsList}
                  defaultValue={sub.numberOfClients}
                  onChange={(value) => setNumberOfClients(value)}
                />
                <FormInput
                  type="select"
                  id="amountOfDiskSpace"
                  name="amountOfDiskSpace"
                  label="Amount of Disk Space"
                  value={amountOfDiskSpace}
                  options={amountOfDiskSpaceList}
                  defaultValue={sub.amountOfDiskSpace}
                  onChange={(value) => setAmountOfDiskSpace(value)}
                />

                <FeaturesList features={sub.features} />

                <SubscriptionButton
                  sub={sub}
                  subscriptionLevel={subscriptionLevel}
                  upgradeSubscription={upgradeSubscription}
                  price={additionalPrice + sub.startingPrice}
                  purchaseSubscription={purchaseSubscription}
                />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
export default PricingInfo;

const FeaturesList = ({ features }) => {
  features = features.replaceAll("\n", "");
  let featureList = features.split(",");
  return (
    <ul className="list-unstyled">
      {featureList.map((feature, index) => {
        let icon;
        let data = feature.split(":");
        let name = data[1].trim();
        if (data[0] === "price") {
          icon = "plus";
        } else {
          icon = "check";
        }
        return <SubFeature key={`feature${index}`} name={name} icon={icon} />;
      })}
    </ul>
  );
};

const SubFeature = ({ name, icon }) => (
  <li className="border-bottom my-2">
    <span
      className={`fas fa-${icon} text-primary`}
      data-fa-transform="shrink-2"
    />{" "}
    {name}
  </li>
);

const SubscriptionButton = ({
  subscriptionLevel,
  sub,
  upgradeSubscription,
  price,
  purchaseSubscription,
}) => {
  let status = parseInt(sub.status);
  if (subscriptionLevel) {
    if (subscriptionLevel === sub.id) {
      return (
        <button
          className="btn btn-primary d-block w-100"
          type="button"
          onClick={() => upgradeSubscription(sub.id)}
        >
          Upgrade
        </button>
      );
    }
  }

  if (!status) {
    return (
      <button
        className="btn btn-secondary disabled d-block w-100"
        type="button"
      >
        Coming Soon
      </button>
    );
  }

  return (
    <button
      className="btn btn-primary d-block w-100"
      type="button"
      onClick={() => purchaseSubscription(sub.id, price)}
    >
      Purchase Now
    </button>
  );
};
