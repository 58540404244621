import React from "react";
const ReferralProgram = () => {
  return (
    <section className="py-0 overflow-hidden" id="banner" data-bs-theme="light">
      <div
        className="bg-holder overlay"
        style={{
          backgroundImage: "url(/assets/img/generic/bg-1.jpg)",
          backgroundPosition: "center bottom",
        }}
      />
      <div className="container">
        <div className="row flex-center pt-1 pt-lg-8 pb-lg-9 pb-xl-0">
          <div className="col-12">
            <h1 className="text-white text-center">Referral Program</h1>
          </div>
          <div className="col-12">
            <div className="card mb-3">
              <div className="card-body">Coming Soon</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ReferralProgram;
