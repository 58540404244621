import React, { useReducer, useState } from "react";
import { Helmet } from "react-helmet";
import { FormInput } from "cmation-formsbuilder";

function SignUpUserInformation({
  processSubscription,
  backStep,
  data,
  screenError,
}) {
  const [error, setError] = useState(screenError || []);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [sameAsPersonal, setSameAsPersonal] = useState(
    data.sameAsPersonal || true
  );

  const [billingFirstName, setBillingFirstName] = useState(
    data.billingFirstName || ""
  );
  const [billingLastName, setBillingLastName] = useState(
    data.billingLastName || ""
  );
  const [billingPhone, setBillingPhone] = useState(data.billingPhone || "");
  const [billingAddress1, setBillingAddress1] = useState(
    data.billingAddress1 || ""
  );
  const [billingAddress2, setBillingAddress2] = useState(
    data.billingAddress2 || ""
  );
  const [billingCity, setBillingCity] = useState(data.billingCity || "");
  const [billingState, setBillingState] = useState(data.billingState || "");
  const [billingZip, setBillingZip] = useState(data.billingZip || "");

  const checkForErrors = () => {
    let errorTMP = error;
    let hasError = false;
    if (!sameAsPersonal) {
      if (!billingFirstName) {
        errorTMP["billingFirstName"] = "This field is required!";
        hasError = true;
      } else {
        delete errorTMP["billingFirstName"];
      }
      if (!billingLastName) {
        errorTMP["billingLastName"] = "This field is required!";
        hasError = true;
      } else {
        delete errorTMP["billingLastName"];
      }
      if (!billingPhone) {
        errorTMP["billingPhone"] = "This field is required!";
        hasError = true;
      } else {
        delete errorTMP["billingPhone"];
      }
      if (!billingAddress1) {
        errorTMP["billingAddress1"] = "This field is required!";
        hasError = true;
      } else {
        delete errorTMP["billingAddress1"];
      }
      if (!billingCity) {
        errorTMP["billingCity"] = "This field is required!";
        hasError = true;
      } else {
        delete errorTMP["billingCity"];
      }
      if (!billingState) {
        errorTMP["billingState"] = "This field is required!";
        hasError = true;
      } else {
        delete errorTMP["billingState"];
      }
      if (!billingZip) {
        errorTMP["billingZip"] = "This field is required!";
        hasError = true;
      } else {
        delete errorTMP["billingZip"];
      }
    }
    if (hasError) {
      setError(errorTMP);
      forceUpdate();
      return true;
    }

    return false;
  };

  const continueSignup = () => {
    if (!checkForErrors()) {
      processSubscription(4, {
        sameAsPersonal: sameAsPersonal,

        billingFirstName: billingFirstName,
        billingLastName: billingLastName,
        billingPhone: billingPhone,
        billingAddress1: billingAddress1,
        billingAddress2: billingAddress2,
        billingCity: billingCity,
        billingState: billingState,
        billingZip: billingZip,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Simply Collaborate | Billing Information</title>
        <meta name="description" content="" />
        <meta name="robots" content="" />
        <meta name="googlebot" content="" />
        <meta name="keywords" content="" />
        <meta name="theme-color" content="#008f68" />
      </Helmet>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="container">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="row">
                  <div className="col-12 mt-4">
                    <h2>Billing Information</h2>
                  </div>
                  <FormInput
                    type="toggle"
                    name="sameAsPersonal"
                    label="Same as personal information"
                    defaultValue={sameAsPersonal}
                    value={sameAsPersonal}
                    colSize={1}
                    placeholder=""
                    onChange={(value) => setSameAsPersonal(value)}
                  />
                  {!sameAsPersonal && (
                    <>
                      <FormInput
                        type="text"
                        name="billingFirstName"
                        label="First Name"
                        colSize={3}
                        value={billingFirstName}
                        placeholder="First Name"
                        onChange={(value) => setBillingFirstName(value)}
                        error={error["billingFirstName"]}
                      />
                      <FormInput
                        type="text"
                        name="billingLastName"
                        label="Last Name"
                        colSize={3}
                        value={billingLastName}
                        placeholder="Last Name"
                        onChange={(value) => setBillingLastName(value)}
                        error={error["billingLastName"]}
                      />

                      <FormInput
                        type="text"
                        name="billingPhone"
                        label="Phone"
                        colSize={3}
                        value={billingPhone}
                        placeholder="Phone"
                        onChange={(value) => setBillingPhone(value)}
                        error={error["billingPhone"]}
                      />
                      <FormInput
                        type="text"
                        name="billingAddress1"
                        label="Address"
                        colSize={2}
                        value={billingAddress1}
                        placeholder="Address"
                        onChange={(value) => setBillingAddress1(value)}
                        error={error["billingAddress1"]}
                      />
                      <FormInput
                        type="text"
                        name="billingAddress2"
                        label="Address 2"
                        colSize={2}
                        value={billingAddress2}
                        placeholder="Address continued"
                        onChange={(value) => setBillingAddress2(value)}
                        error={error["billingAddress2"]}
                      />
                      <FormInput
                        type="text"
                        name="billingCity"
                        label="City"
                        colSize={3}
                        value={billingCity}
                        placeholder="City"
                        onChange={(value) => setBillingCity(value)}
                        error={error["billingCity"]}
                      />
                      <FormInput
                        type="state"
                        name="billingState"
                        label="State"
                        colSize={3}
                        value={billingState}
                        placeholder="State"
                        onChange={(value) => setBillingState(value)}
                        error={error["billingState"]}
                      />
                      <FormInput
                        type="text"
                        name="billingZip"
                        label="Zip"
                        colSize={3}
                        value={billingZip}
                        placeholder="Zip"
                        onChange={(value) => setBillingZip(value)}
                        error={error["billingZip"]}
                      />
                    </>
                  )}
                </div>
                <div className="row py-3 justify-content-center">
                  <div className="col-6">
                    <button
                      type={"button"}
                      className="btn btn-sm btn-secondary"
                      onClick={backStep}
                    >
                      Back
                    </button>
                  </div>
                  <div className="col-6 text-end">
                    <button
                      type={"submit"}
                      className="btn btn-sm btn-primary"
                      onClick={continueSignup}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUpUserInformation;
