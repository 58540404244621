import React from "react";
import classnames from "classnames";

const FormLinkedTable = (props) => {
  const { columns, data } = props.results;
  return (
    <div className={`col-12 align-bottom col-sm-${props.divColSize}`}>
      <div className="container p-0">
        <div className="row">
          <label className={classnames("col-sm-12 m-0 pt-2 pb-2")}>
            {props.label}
          </label>

          <div className="col-sm-12">
            <table className="table table-sm table-striped">
              <thead className="thead-dark">
                <tr>
                  {columns.map((column, i) => {
                    return <th key={`headerRow${i}`}>{column.displayName}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {data.map((data, i) => {
                  return (
                    <tr key={`dataRow${i}`}>
                      {columns.map((column, e) => {
                        return (
                          <td key={`data_${i}_${e}`}>
                            {data[column.fieldName]}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormLinkedTable;
