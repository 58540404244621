import React, { useReducer, useState } from "react";
import { Helmet } from "react-helmet";
import { FormInput } from "cmation-formsbuilder";

function SignUpUserInformation({
  processSubscription,
  backStep,
  data,
  screenError,
}) {
  const [error, setError] = useState(screenError || []);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [firstName, setFirstName] = useState(data.firstName || "");
  const [lastName, setLastName] = useState(data.lastName || "");
  const [personalPhone, setPersonalPhone] = useState(data.personalPhone || "");
  const [personalAddress1, setPersonalAddress1] = useState(
    data.personalAddress1 || ""
  );
  const [personalAddress2, setPersonalAddress2] = useState(
    data.personalAddress2 || ""
  );
  const [personalCity, setPersonalCity] = useState(data.personalCity || "");
  const [personalState, setPersonalState] = useState(data.personalState || "");
  const [personalZip, setPersonalZip] = useState(data.personalZip || "");

  const checkForErrors = () => {
    let errorTMP = error;
    let hasError = false;
    if (!firstName) {
      errorTMP["firstName"] = "This field is required!";
      hasError = true;
    } else {
      delete errorTMP["firstName"];
    }
    if (!lastName) {
      errorTMP["lastName"] = "This field is required!";
      hasError = true;
    } else {
      delete errorTMP["lastName"];
    }
    if (!personalPhone) {
      errorTMP["personalPhone"] = "This field is required!";
      hasError = true;
    } else {
      delete errorTMP["personalPhone"];
    }
    if (!personalAddress1) {
      errorTMP["personalAddress1"] = "This field is required!";
      hasError = true;
    } else {
      delete errorTMP["personalAddress1"];
    }
    if (!personalCity) {
      errorTMP["personalCity"] = "This field is required!";
      hasError = true;
    } else {
      delete errorTMP["personalCity"];
    }
    if (!personalState) {
      errorTMP["personalState"] = "This field is required!";
      hasError = true;
    } else {
      delete errorTMP["personalState"];
    }
    if (!personalZip) {
      errorTMP["personalZip"] = "This field is required!";
      hasError = true;
    } else {
      delete errorTMP["personalZip"];
    }
    if (hasError) {
      setError(errorTMP);
      forceUpdate();
      return true;
    }
    return false;
  };

  const continueSignup = () => {
    if (!checkForErrors()) {
      processSubscription(3, {
        firstName: firstName,
        lastName: lastName,
        personalPhone: personalPhone,
        personalAddress1: personalAddress1,
        personalAddress2: personalAddress2,
        personalCity: personalCity,
        personalState: personalState,
        personalZip: personalZip,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Simply Collaborate | Personal Information</title>
        <meta name="description" content="" />
        <meta name="robots" content="" />
        <meta name="googlebot" content="" />
        <meta name="keywords" content="" />
        <meta name="theme-color" content="#008f68" />
      </Helmet>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="container">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="row">
                  <div className="col-12">
                    <h2>Personal Information</h2>
                  </div>
                  <FormInput
                    type="text"
                    name="firstName"
                    label="First Name"
                    colSize={3}
                    value={firstName}
                    placeholder="First Name"
                    onChange={(value) => setFirstName(value)}
                    error={error["firstName"]}
                  />
                  <FormInput
                    type="text"
                    name="lastName"
                    label="Last Name"
                    colSize={3}
                    value={lastName}
                    placeholder="Last Name"
                    onChange={(value) => setLastName(value)}
                    error={error["lastName"]}
                  />
                  <FormInput
                    type="text"
                    name="personalPhone"
                    label="Phone"
                    colSize={3}
                    value={personalPhone}
                    placeholder="Phone"
                    onChange={(value) => setPersonalPhone(value)}
                    error={error["personalPhone"]}
                  />
                  <FormInput
                    type="text"
                    name="personalAddress1"
                    label="Address"
                    colSize={2}
                    value={personalAddress1}
                    placeholder="Address"
                    onChange={(value) => setPersonalAddress1(value)}
                    error={error["personalAddress1"]}
                  />
                  <FormInput
                    type="text"
                    name="personalAddress2"
                    label="Address 2"
                    colSize={2}
                    value={personalAddress2}
                    placeholder="Address continued"
                    onChange={(value) => setPersonalAddress2(value)}
                    error={error["personalAddress2"]}
                  />
                  <FormInput
                    type="text"
                    name="personalCity"
                    label="City"
                    colSize={3}
                    value={personalCity}
                    placeholder="City"
                    onChange={(value) => setPersonalCity(value)}
                    error={error["personalCity"]}
                  />
                  <FormInput
                    type="state"
                    name="personalState"
                    label="State"
                    colSize={3}
                    value={personalState}
                    placeholder="State"
                    onChange={(value) => setPersonalState(value)}
                    error={error["personalState"]}
                  />
                  <FormInput
                    type="text"
                    name="personalZip"
                    label="Zip"
                    colSize={3}
                    value={personalZip}
                    placeholder="Zip"
                    onChange={(value) => setPersonalZip(value)}
                    error={error["personalZip"]}
                  />
                </div>
                <div className="row py-3 justify-content-center">
                  <div className="col-6">
                    <button
                      type={"button"}
                      className="btn btn-sm btn-secondary"
                      onClick={backStep}
                    >
                      Back
                    </button>
                  </div>
                  <div className="col-6 text-end">
                    <button
                      type={"submit"}
                      className="btn btn-sm btn-primary"
                      onClick={continueSignup}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUpUserInformation;
