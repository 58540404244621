import { ApiURL, auth } from "../../config";
import Loading from "../../components/layouts/Loader";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Workspace = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = () => {
      fetch(`${ApiURL}/auth/user`, {
        method: "GET",
        headers: { "Content-Type": "application/json", Authorization: token },
        credentials: "include",
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === true) {
            window.location.href = `https://${res.userInfo.workspace}.simplycollaborate.com`;
          } else {
            if (auth) {
              localStorage.removeItem("token");
              navigate("/auth/login");
            }
          }
        })
        .catch((err) => {
          console.log("authLogin Error", err);
        });
    };
    checkAuth();
  }, [navigate, token]);

  return <Loading />;
};
export default Workspace;
