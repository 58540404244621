import React, { useEffect, useState } from "react";
import { FormInput } from "cmation-formsbuilder";
import Loading from "../../components/layouts/Loader";
import { ApiURL, auth } from "../../config";
import { useNavigate } from "react-router-dom";
const token = localStorage.getItem("token");

const Billing = () => {
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const navigate = useNavigate();

  const [billingFirstName, setBillingFirstName] = useState("");
  const [billingLastName, setBillingLastName] = useState("");
  const [billingPhone, setBillingPhone] = useState("");
  const [billingAddress1, setBillingAddress1] = useState("");
  const [billingAddress2, setBillingAddress2] = useState("");
  const [billingCity, setBillingCity] = useState("");
  const [billingState, setBillingState] = useState("");
  const [billingZip, setBillingZip] = useState("");

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage("");
        setEditMode(false);
      }, 3000);
    }
  }, [successMessage]);

  const submitForm = (e) => {
    e.preventDefault();
    let errorsFound = [];
    let error = false;
    if (!billingFirstName) {
      errorsFound.billingFirstName = "Field is required and can not be blank";
      error = true;
    }
    if (!billingLastName) {
      errorsFound.billingLastName = "Field is required and can not be blank";
      error = true;
    }
    if (!billingPhone) {
      errorsFound.billingPhone = "Field is required and can not be blank";
      error = true;
    }
    if (!billingAddress1) {
      errorsFound.billingAddress1 = "Field is required and can not be blank";
      error = true;
    }
    if (!billingCity) {
      errorsFound.billingCity = "Field is required and can not be blank";
      error = true;
    }
    if (!billingState) {
      errorsFound.billingState = "Field is required and can not be blank";
      error = true;
    }
    if (!billingZip) {
      errorsFound.billingZip = "Field is required and can not be blank";
      error = true;
    }

    if (error) {
      setErrors(errorsFound);
    } else {
      setErrors([]);
      saveChanges();
    }
  };
  const saveChanges = () => {
    setButtonLoading(true);
    let data = {
      billingFirstName,
      billingLastName,
      billingPhone,
      billingAddress1,
      billingAddress2,
      billingCity,
      billingState,
      billingZip,
    };
    fetch(`${ApiURL}/saveBilling`, {
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: token },
      credentials: "include",
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        setButtonLoading(false);

        if (res.status === true) {
          setErrors([]);
          setSuccessMessage(res.message);
        } else {
          console.log("fail 2", res);
          setErrors(res.fields);
        }
      })
      .catch((err) => {
        console.log("authLogin Error", err);
      });
  };

  const checkAuth = (data) => {
    fetch(`${ApiURL}/auth/user`, {
      method: "GET",
      headers: { "Content-Type": "application/json", Authorization: token },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.status === true) {
          setBillingFirstName(
            res.userInfo.sameAsPersonal === "1"
              ? res.userInfo.firstName
              : res.userInfo.billingFirstName
          );
          setBillingLastName(
            res.userInfo.sameAsPersonal === "1"
              ? res.userInfo.lastName
              : res.userInfo.billingLastName
          );
          setBillingPhone(
            res.userInfo.sameAsPersonal === "1"
              ? res.userInfo.personalPhone
              : res.userInfo.billingPhone
          );
          setBillingAddress1(
            res.userInfo.sameAsPersonal === "1"
              ? res.userInfo.personalAddress1
              : res.userInfo.billingAddress1
          );
          setBillingAddress2(
            res.userInfo.sameAsPersonal === "1"
              ? res.userInfo.personalAddress2
              : res.userInfo.billingAddress2
          );
          setBillingCity(
            res.userInfo.sameAsPersonal === "1"
              ? res.userInfo.personalCity
              : res.userInfo.billingCity
          );
          setBillingState(
            res.userInfo.sameAsPersonal === "1"
              ? res.userInfo.personalState
              : res.userInfo.billingState
          );
          setBillingZip(
            res.userInfo.sameAsPersonal === "1"
              ? res.userInfo.personalZip
              : res.userInfo.billingZip
          );
        } else {
          if (auth) {
            localStorage.removeItem("token");
            navigate("/auth/login");
          }
        }
      })
      .catch((err) => {
        console.log("authLogin Error", err);
      });
  };

  useEffect(() => {
    if (billingFirstName === "") {
      checkAuth();
    }
    // update
  }, [
    billingFirstName,
    billingLastName,
    billingPhone,
    billingAddress1,
    billingAddress2,
    billingCity,
    billingState,
    billingZip,
    errors,
  ]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="card mb-3">
            <div className="card-body">
              <h1>Billing Information</h1>
              {successMessage && (
                <div className="alert alert-success" role="alert">
                  <div className="alert-success">{successMessage}</div>
                </div>
              )}
              <form onSubmit={submitForm}>
                <div className="container">
                  {!editMode && (
                    <div className="row">
                      <div className="col-12 text-end">
                        <button
                          className="btn btn-outline-secondary"
                          onClick={toggleEditMode}
                        >
                          edit
                        </button>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <FormInput
                      id="billingFirstName"
                      label="First Name"
                      name="billingFirstName"
                      value={billingFirstName}
                      type={editMode ? "text" : "display"}
                      colSize={3}
                      onChange={(value) => setBillingFirstName(value)}
                      required={true}
                      error={errors?.billingFirstName ?? null}
                    />
                    <FormInput
                      id="billingLastName"
                      label="Last Name"
                      name="billingLastName"
                      value={billingLastName}
                      type={editMode ? "text" : "display"}
                      colSize={3}
                      onChange={(value) => setBillingLastName(value)}
                      required={true}
                      error={errors?.billingLastName ?? null}
                    />
                    <FormInput
                      id="billingPhone"
                      label="Phone"
                      name="billingPhone"
                      value={billingPhone}
                      type={editMode ? "text" : "display"}
                      colSize={3}
                      onChange={(value) => setBillingPhone(value)}
                      required={true}
                      error={errors?.billingPhone ?? null}
                    />
                    <FormInput
                      id="billingAddress1"
                      label="Address"
                      name="billingAddress1"
                      value={billingAddress1}
                      type={editMode ? "text" : "display"}
                      colSize={2}
                      onChange={(value) => setBillingAddress1(value)}
                      required={true}
                      error={errors?.billingAddress1 ?? null}
                    />
                    <FormInput
                      id="billingAddress2"
                      label="Address cont."
                      name="billingAddress2"
                      value={billingAddress2}
                      type={editMode ? "text" : "display"}
                      colSize={2}
                      onChange={(value) => setBillingAddress2(value)}
                      error={errors?.billingAddress2 ?? null}
                    />
                    <FormInput
                      id="billingCity"
                      label="City"
                      name="billingCity"
                      value={billingCity}
                      type={editMode ? "text" : "display"}
                      colSize={3}
                      onChange={(value) => setBillingCity(value)}
                      required={true}
                      error={errors?.billingCity ?? null}
                    />
                    <FormInput
                      id="billingState"
                      label="State"
                      name="billingState"
                      value={billingState}
                      type={editMode ? "state" : "display"}
                      colSize={3}
                      onChange={(value) => setBillingState(value)}
                      required={true}
                      error={errors?.billingState ?? null}
                    />
                    <FormInput
                      id="billingZip"
                      label="Zip"
                      name="billingZip"
                      value={billingZip}
                      type={editMode ? "text" : "display"}
                      colSize={3}
                      onChange={(value) => setBillingZip(value)}
                      required={true}
                      error={errors?.billingZip ?? null}
                    />
                  </div>
                  {editMode && (
                    <div className="row mt-2">
                      <div className="col-6">
                        <button
                          className="btn btn-outline-secondary"
                          onClick={toggleEditMode}
                        >
                          cancel
                        </button>
                      </div>
                      <div className="col-6 text-end">
                        {buttonLoading ? (
                          <div
                            className="spinner-border text-warning"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          <button
                            className="btn btn-success"
                            onClick={submitForm}
                          >
                            Save
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Billing;
