import React from "react";
import classnames from "classnames";
import InputError from "./InputError";
import InputHelpText from "./InputHelpText";

const FormInputText = (props) => {
  const changeValue = (value) => {
    props.onChange(value);
  };
  let divColSize;
  let inputSizeClass;
  if (typeof props.inputSizeClass === "undefined") {
    inputSizeClass = "form-control";
    if (props.inputSize === "sm") inputSizeClass += " form-control-sm";
    if (props.inputSize === "lg") inputSizeClass += " form-control-lg";

    let floatingLabelClass = "form-group";
    if (props.floatingLabel) floatingLabelClass = " form-floating";

    divColSize = 12;
    if (props.colSize === 0) divColSize = 0;
    if (props.colSize === 1) divColSize = 12;
    if (props.colSize === 2) divColSize = 6;
    if (props.colSize === 3) divColSize = 4;
    if (props.colSize === 4) divColSize = 8;
  }

  if (props.floatingLabel) {
    return (
      <div
        className={`col-12 align-bottom col-sm-${
          divColSize ? divColSize : props.divColSize
        }`}
      >
        <div className="form-floating mb-3">
          <input
            ref={props.refProp ?? null}
            id={props.name}
            name={props.name}
            type={props.type}
            autoComplete={props.autoComplete ? props.autoComplete : "off"}
            value={props.value ?? ""}
            className={classnames(
              inputSizeClass ? inputSizeClass : props.inputSizeClass,
              {
                "is-invalid": props.error,
              }
            )}
            onBlur={props.onBlur}
            onChange={(e) => changeValue(e.target.value)}
            aria-describedby={props.name + "Help"}
            placeholder={props.placeholder}
          />
          <label
            htmlFor={props.name}
            className={classnames("", {
              "is-invalid": props.error,
            })}
          >
            {props.label}
          </label>
          {props.required === true && (
            <small className="text-danger">&nbsp; * Required field</small>
          )}
          <InputError error={props.error} />
          <InputHelpText helpText={props.helpText} />
        </div>
      </div>
    );
  }

  return (
    <div
      className={`col-12 align-bottom col-sm-${
        divColSize ? divColSize : props.divColSize
      }`}
    >
      <div className="container p-0">
        <div
          className={`form-group row ${
            !props.hideBorder ? "border-bottom" : ""
          } ${!props.singleItem ? "pb-2" : ""} `}
        >
          {props.label && (
            <label
              htmlFor={props.name}
              className={classnames("col-sm-12 m-0 pt-2 pb-2", {
                "is-invalid": props.error,
              })}
            >
              {props.label}
              {props.required === true && (
                <>
                  {" "}
                  <small className="text-danger">* Required field</small>
                </>
              )}
            </label>
          )}
          <div className="col-sm-12">
            <div className="input-group">
              <input
                ref={props.refProp ?? null}
                id={props.name}
                name={props.name}
                type={props.type}
                autoComplete={props.autoComplete ? props.autoComplete : "off"}
                value={props.value === null ? "" : props.value}
                className={classnames(
                  inputSizeClass ? inputSizeClass : props.inputSizeClass,
                  {
                    "is-invalid": props.error,
                  }
                )}
                onBlur={props.onBlur}
                onChange={(e) => changeValue(e.target.value)}
                aria-describedby={props.name + "Help"}
                placeholder={props.placeholder}
              />
              {props.children}
            </div>
          </div>

          <InputError error={props.error} />
          <InputHelpText helpText={props.helpText} />
        </div>
      </div>
    </div>
  );
};

export default FormInputText;
